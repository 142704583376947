.HomeAouImpact {
  background-color: var(--clr-white);
  margin-top: 1%;
}
.HomeAouImpact-bg {
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--blue-tranparent);
}
.HomeAouImpact-detail {
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;
}
.HomeAouImpact-detail div h1 {
  font-size: 3.4rem;
  color: var(--clr-blue);
}
.HomeAouImpact-detail p {
  color: var(--text-clr);
  margin-top: 4%;
}
.HomeAouImpact-detail-logo {
  align-items: center;
  display: flex;
  height: 50px;
}
.HomeAouImpact-detail-logo img {
  height: 1rem;
  padding: 0rem 1rem;
}
.HomeAouImpact-detail-2 {
  display: flex;
  text-align: center;
  display: flex;
  width: 90%;
  height: auto;
  justify-content: space-evenly;
  margin: 0;
}

.HomeAouImpact-detail-2 h1 {
  margin: 0rem 0rem;
  font-size: 4rem;
}
.HomeAouImpact-detail-2 p {
  color: var(--text-clr);
}
.HomeAouImpact-detail-2-space {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homeAouimpact-flex {
  display: block;
}
.text_Homeaboimpact {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.text_Homeaboimpact p {
  margin-top: 0.6rem;
}
.text_Homeaboimpact h1 {
  margin: 0;
}

.text-line_conatiner {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.line_of_homeabimpact {
  width: 50%;
  height: 6px;
  border-radius: 5px;
  background-image: linear-gradient(to right, #ee6c4d, #0c4c78);
  position: relative;
}
.circle_round {
  width: 150px;
  height: 150px;
  background-color: transparent;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 5px dashed #ee6c4d;
  animation: rotate 5s linear infinite;
  position: relative;
}
.text-round {
  width: 160px;
  height: 160px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.text-round h1 {
  font-size: 2rem;
  font-family: "Arial";
  font-weight: 700;
  color: #0c4c78;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*------------ mobile responsive ----------- */
@media only screen and (max-width: 768px) {
  .text_Homeaboimpact {
    width: 100%;
    height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
  }
  .circle_round{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 65px;
    height: 65px;
  }
  .text-round {
    position: absolute;
    top: 0;
    margin: auto;
    width: 65px;
    height: 65px;
    text-align: center;
  }

  .HomeAouImpact-detail-2 {
    display: grid;
    text-align: center;
    /* display: flex; */
    width: 100%;
    justify-content: space-evenly;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
  }

  .circle_round {
    width: 60px;
    height: 60px;
    border: 3px dashed #ee6c4d;
  }
  .HomeAouImpact-detail-2 p {
    color: var(--text-clr);
    font-size: 0.7rem;
  }
  .text-round h1 {
    font-size: 1rem;
    font-weight: 400;
  }
  .text-line_conatiner {
    width: 100%;
  }
  .HomeAouImpact-detail div h1 {
    font-size: 2.5rem;
    color: var(--clr-blue);
  }
  .HomeAouImpact-bg {
    padding: 1rem 0rem;
  }
  .line_of_homeabimpact {
    margin-top: 0.5rem;
    height: 4px;
}
}





