.HomeForm{
    width: 100%;
    height: 50vh;
    background-color: var(--blue-tranparent);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
}
.homeform_text{
    width: 100%;
    height: auto;
    /* background-color: rgb(254, 199, 199); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.homeform_text h1{
    color: #0C4C78;
    font-family: 'Arial';
    font-weight: 700;
}
.homeform_line{
    width:20%;
    height: 8px;
    border-radius:5px;
    background-image: linear-gradient(to right, #ee6c4d, #0c4c78);
    margin-top: -10px;
}
.homeform_fildes_container{
    width: 100%;
    height: 30vh;
    /* background-color: rgb(178, 231, 178); */
    display: flex;
    justify-content: space-around;
    gap: 2px;
    align-items: center;
}
.homeform_box1{
    width: 15%;
    height: auto;
    /* background-color: green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.homeform_box1 span{
    color: #EE6C4D;
    font-size: 25px;
    font-weight: 400;
    font-family: 'Arial';

}
.homeform_box1 p{
    font-size: 20px;
    font-weight: 400;
    font-family: 'Arial';
    color: #0C4C78;
}

/*  */
.homeform_box1_input{
    width: 20%;
    height: 150px;
    /* background-color: green; */
    display: flex;
    flex-direction: column;
}
.homeform_box1_input p{
    font-size: 24px;
    font-family: 'Arial';
    font-weight: 400;
    color: #505050;
}
.homeform_box1_input p span{
    color:red;
}
.homeform_input_box{
    width: 100%;
    height: 60px;
    /* background-color: red; */
    margin-top: -20px;
    display: flex;
    /* justify-content: end; */
    border-left: 5px solid #0C4C78;
    border-radius: 5px;
    /* border: ; */
    
}
.homeform_input_box input{
    width: 95%;
    height: 100%;
    padding: 0rem 0rem 0 1rem;
    outline: 0;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.btn_box{
    width: 15%;
    height: 140px;
    /* background-color:green; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
   
    
}
.homeform_btn{
    width: 174px;
    height: 60px;
    border-radius: 5px;
    background-color:  #0C4C78;
    cursor: pointer;
    color:white;
    font-size:23px;
    font-weight: 400;
    font-family: 'Arial';
    margin-top: 20px;
    border: none;
}






/*------------ mobile responsive ----------- */
@media only screen and (max-width: 768px) {
    .homeform_text h1 {
        font-size: 20px;
        padding: 0 1rem;
    }
    .homeform_text {
        
        align-items: start;
        padding: 0 0rem;
    }
    .homeform_line {
        margin-top: -10px;
        margin-left: 1rem;
        height: 4px;
    }
    .HomeForm {
        width: 100%;
        height: auto;
        gap: 1.125rem;
        padding: 1rem 0;

        /* background-color: var(--blue-tranparent);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; */
    }
   
    .homeform_box1 {
        width: 90%;
        height: auto;
        /* background-color: green; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
    }
    .homeform_fildes_container {
        width: 90%;
        height: auto;
        /* background-color: rgb(178, 231, 178); */
        display: flex;
        justify-content: space-around;
        gap: 31px;
        align-items: center;
        padding: 1rem 0;
        border: 2px solid rgb(185, 185, 185);
        flex-direction: column;
        border-radius: .625rem;
    }
    .homeform_box1_input {
        width: 90%;
        height: auto;
        /* background-color: green; */
        display: flex;
        flex-direction: column;
    }
    .none_mobile_view{
        display: none;
    }
    .p-none-mobile-view{
        display: none;
    }
    .homeform_btn {
        width: 100px;
        height: 42px;
       
        font-size: 17px;
       
    }
    .btn_box {
        width: 90%;
        height: auto;
        /* background-color: green; */
        display: flex;
        justify-content: end;
    }

}