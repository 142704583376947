.RiyoSignUp {
  padding: 0% 4%;
}

.RiyoSignUp-heading h1 {
  font-size: 4rem;
  color: var(--clr-blue);
  font-weight: 450;
}

.RiyoSignUp-details-main {
  display: flex;
  width: 100%;
}
.RiyoSignUp-details {
  height: auto;
  width: 70%;
  margin-top: 3%;
  display: flex;
  gap: -1rem;
}

.RiyoSignUp-screenShot {
  padding: 0.3rem;
  background-color: var(--clr-black);
  width: 70%;
  height: 20rem;
  border-radius: 1rem;
  position: relative;
  display: flex;
  justify-content: center;
}

.RiyoSignUp-screenShot img {
  border-radius: 1rem;
  object-fit: cover;
}

.mobile-notch {
  top: 4px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5%;
  width: 50%;
  background-color: black;
  border-radius: 0 0 0.7rem 0.7rem;
}
.RiyoSignUp-heading-line {
  width: 9rem;
  height: 5px;
  background: linear-gradient(to right, var(--clr-orange), var(--clr-blue));
  border-radius: 0px 100px 100px 0px;
  margin-top: 1%;
}
.RiyosignUpJion-paragraph {
  width: 80%;
}
.RiyosignUpJion-paragraph h1 {
  color: var(--clr-orange);
  font-weight: 400;
  text-shadow: 0 2px 3px var(--clr-black);
}
.RiyosignUpJion-paragraph p {
  margin-top: 3%;
  text-shadow: 0 1px 1px var(--clr-black);
  line-height: 2.3rem;
  font-size: 17px;
  color: var(--text-clr);
}
.RiyosignUpJion-QR-Code {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--text-clr);
  text-align: center;
  width: 20%;
}
.RiyosignUpJion-QR-Code h2 {
  padding-bottom: 3%;
  font-weight: 600;
}
.RiyosignUpJion-QR-Code-box {
  border-radius: 0.6rem;
  padding: 6%;
  width: 50%;
  background: var(--clr-qr);
  height: 6rem;
  position: relative;
}
.RiyosignUpJion-QR-Code-box img{
  height: 100%;
}
.RiyosignUpJion-QR-Code-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.RiyosignUpJionDetail {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 2% 0%;
}
.RiyosignUpJionDetail2 {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.Mobile-box {
  display: none;
}
@media only screen and (max-width: 760px) {
  
  .RiyoSignUp {
    padding: 3% 4%;
  }
  .Mobile-box {
    position: relative;
    top: 2rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .Mobile-box img {
    position: relative;
    height: 4rem;
  }
  .RiyosignUpJionDetail2 {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .RiyoSignUp-details-main {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .RiyoSignUp-heading-line {
    width: 4rem;
    height: 2px;
  }
  .RiyoSignUp-screenShot {
    width: 90%;
  }
  .RiyoSignUp-details {
    height: auto;
    gap: 3rem;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
  }
  .RiyoSignUp-heading h1 {
    font-size: 1.4rem;
  }
  .RiyosignUpJionDetail {
    width: 100%;
    align-items: start;
    flex-direction: column;
  }
  .RiyosignUpJion-paragraph {
    width: 100%;
  }
  .RiyosignUpJion-paragraph h1 {
    font-size: 1.5rem;
    text-align: start;
  }
  .RiyosignUpJion-QR-Code {
    width: 100%;
    flex-direction: row;
  }
  .RiyosignUpJion-QR-Code-box {
    border-radius: 0.6rem;
    background: var(--clr-qr);
    position: relative;
  }
  /* .RiyosignUpJion-QR-Code-box img{
   height: 80%;


  } */
  .RiyosignUpJion-QR-Code-div {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    width: 50%;
  }
  .RiyosignUpJion-QR-Code-div p {
    text-align: start;
    font-size: 10rem;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .RiyoSignUp-screenShot {
    width: 70%;
    height: 16rem;
  }
  .RiyosignUpJion-paragraph p {
    font-size: 13px;
    line-height: 1.4rem;
  }
}

@media (max-width: 1280px) and (min-width: 1025px) {
  .RiyoSignUp-screenShot {
    width: 70%;
    height: 19rem;
  }
}

@media (max-width: 1440px) and (min-width: 1281px) {
  .RiyoSignUp-screenShot {
    width: 60%;
    height: 17rem;
  }
  .RiyosignUpJion-paragraph p {
    font-size: 18px;
  }
}

@media (max-width: 1600px) and (min-width: 1440px) {
  .RiyoSignUp-screenShot {
    width: 60%;
    height: 18rem;
  }
  .RiyosignUpJion-paragraph p {
    font-size: 19px;
  }
}

@media (max-width: 1800px) and (min-width: 1601px) {
  .RiyoSignUp-screenShot {
    width: 60%;
    height: 21rem;
  }
  .RiyosignUpJion-paragraph p {
    font-size: 23px;
  }
}

@media (max-width: 2000px) and (min-width: 1801px) {
  .RiyoSignUp-screenShot {
    width: 50%;
    height: 20rem;
  }
  .RiyosignUpJion-paragraph p {
    font-size: 25px;
  }
}

@media (max-width: 2560px) and (min-width: 2001px) {
  .RiyoSignUp-screenShot {
    width: 50%;
    height: 23rem;
  }
  .RiyosignUpJion-paragraph p {
    font-size: 34px;
    line-height: 3.5rem;
  }
}
