.RiyoLogo {
  padding: 2% 4%;
  height: 50vh;
}
.RiyoLogoHeading h1 {
  font-size: 4rem;
  font-weight: 400;
  color: var(--clr-blue);
}
.RiyoHeadingline {
  margin-top: 2%;
  width: 10rem;
  height: 0.3rem;
  background: linear-gradient(to right, var(--clr-orange), var(--clr-blue));
  border-radius: 0px 100px 100px 0px;
}
.RiyoLogo-all-details {
  width: 100%;
  height: 40%;
  position: relative;
  margin-top: 3%;
  display: flex;
  justify-content: space-between;
}
.RiyoLogo-box {
  padding: 1.4%;
  width: 15%;
  height: 100%;
  background: var(--blue-tranparent-25);
  border-radius: 0.5rem;
  transition: 0.5s;
}
.Logo-box .RiyoLogo-box{
  width: 50%;
}

.RiyoLogo-box p {
  color: var(--clr-blue);
  margin-top: 6%;
  font-weight: 600;
  font-size: 18px;
}

.RiyoLogo-box-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 60%;
  background: var(--clr-blue);
  border-radius: 0.5rem;
}
.RiyoLogo-box-logo img {
  height: 50%;
}

.RiyoLogo-box:hover {
  transform: scale(1.1);
}
.Mobile-responsive{
  display: none;
}




@media only screen and (max-width: 760px) {
  .RiyoLogo {
    height: auto;
  }
 .RiyoLogo-all-details{
  display: none;
 }

 .Mobile-responsive{
  width: 100%;
  height: 40%;
  position: relative;
  margin-top: 3%;
  display: block;
  justify-content: space-between;
 }
  .RiyoLogoHeading h1 {
    font-size: 1.6rem;
  }
  .RiyoHeadingline {
    width: 5rem;
  }
  .RiyoLogo-box{
    position: relative;
    width: 28%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0% 2%;
  }
  .RiyoLogo-box p{
    font-size: 10px;
  }
  .RiyoLogo-box-logo{
    width: 40%;
    height: 40%;
  }
  .mobile{
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .mobile2{
    margin-top: 5%;
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 2rem;
    
  }
  .mobile .RiyoLogo-box{
    
  }
}






@media (min-width: 2000px) and (max-width: 2601px) {
  .RiyoLogo-box p {
    margin-top: 8%;
    font-size: 24px;
  }
}

@media (min-width: 700px) and (max-width: 1024px) {
  .RiyoLogo-box p {
    margin-top: 8%;
    font-size: 14px;
  }
}


