@import url(../../constants/colorConstans.css);
.riyo-servicesapplicaton-div {
  height: auto;
  width: 100%;
  /* background-color:chocolate; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8rem 0;
}
.riyo-servicesapplicaton-div2 {
  height: 50vh;
  width: 88%;
  /* background-color: yellow; */
}
.riyo-servicesapplicaton-scanner p {
  color: var(--clr-black);
  font-family: Arial;
}
.riyo-heading-p {
  font-size: 1.9rem;
  font-weight: 600;
  font-family: Arial;
  color: var(--clr-darkblue);
}
.riyo-servicesapplicaton-img {
  height: 40vh;
  width: 100%;
  /* background-color:dodgerblue; */
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.riyo-servicesapplicaton-scanner {
  height: 29vh;
  width: 16%;
  border-radius: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--clr-orange);
}
.riyo-servicesapplicaton-scanner-box {
  height: 29vh;
  width: 15rem;
  border-radius: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--clr-orange);
}
.riyo-box-text {
  height: 19vh;
  width: 12rem;
  /* background-color: red; */
}
.riyo-box-text p {
  color: var(--clr-white);
  font-size: 0.6rem;
  line-height: 1.6rem;
  font-family: Arial;
}

/*  */
.m_responsive-help{
  width: 83%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: rgb(213, 249, 213); */
}
.riyo-servicesapplicaton-scanner-box2 {
  height: 29vh;
  width: 15rem;
  border-radius: 0.625rem;
  display: flex;
  flex-flow: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}
.riyo-servicesapplicaton-scanner-box3 {
  height: 29vh;
  width: 15rem;
  border-radius: 0.625rem;
  display: flex;
  flex-flow: column;
  gap: 1rem;
  justify-content: space-evenly;
  align-items: center;
  /* background-color: aqua; */
}
.riyo-servicesapplicaton-scanner-box4 {
  height: 29vh;
  width: 15rem;
  border-radius: 0.625rem;
  display: flex;
  flex-flow: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  /* background-color: aqua; */
}
.riyo-servicesapplicaton-scanner-box2 p {
  font-size: 1.4rem;
  font-family: Arial;
}
.riyo-servicesapplicaton-scanner-box3 p {
  font-size: 1.4rem;
  font-family: Arial;
}
.riyo-servicesapplicaton-scanner-box4 p {
  font-size: 1.4rem;
  font-family: Arial;
}
.riyo-servicesapplicaton-logo {
  height: 35vh;
  width: 15%;
  margin-top: -2.8125rem;
  /* background-color: blueviolet; */
}
.flip-card {
  width: 15rem;
  height: 15rem;
  border-radius: 0.9375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  perspective: 62.5rem;
   background-color: transparent;
}
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}
.flip-card-front {
  color: var(--clr-black);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 0.625rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  gap: 0.625rem;
  box-shadow: 0px 11px 15px 0px rgba(0, 0, 0, 0.1);
  /* background-color: #bbb; */
}
.flip-card-front p {
  font-size: 1.3rem;
  font-family: Arial;
}
.flip-card-front img {
  width: 80%;
  height: 80%;
}
.flip-card-back {
  height: 100%;
  width: 100%;
  background-color: var(--clr-orange);
  transform: rotateY(180deg);
  position: absolute;
  color: var(--clr-white);
  border-radius: 0.9375rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.flip-card-back p {
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--clr-white);
  font-family: Arial;
}


/* push */


/*------------ mobile responsive ----------- */
@media only screen and (max-width: 768px) {
  .riyo-heading-p{
    font-size: 1rem;
    margin-top: 0;
}
.riyo-servicesapplicaton-img {
  height: auto;
  gap: .625rem;
  width: 100%;
 
}
.flip-card-front p {
  font-size: 0.725rem;
  font-family: Arial;
  color: var(--clr-darkblue);
}
.m_responsive-help {
  flex-wrap: wrap;
  height: 41vh;

}
.riyo-servicesapplicaton-logo {
  height: 35vh;
  width: 48%;
  /* background-color: blueviolet; */
  margin-top: -2.8125rem;
}
.flip-card {
  background-color: transparent;
  width: 6rem;
  height: 7rem;
  border-radius: 0.9375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  perspective: 62.5rem;
}
.flip-card-front {
  /* background-color: #bbb; */
  
  gap: 0rem;
  /* background: red; */
  padding: 0.625rem 0;
}
.flip-card-back {
  height: 8rem ;
  width: 6rem;
 
}
.flip-card-back p {
  font-size: 0.6rem;
  line-height: 0.4rem;
  color: var(--clr-white);
  font-family: Arial;
}
}
