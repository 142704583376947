@import url(../../constants/colorConstans.css);
.Footer {
  padding: 2% 2%;
  background: linear-gradient(var(--clr-blue), var(--clr-orange));
  position: relative;
  z-index: 3;
}
.Footer p{
  cursor: pointer;
}
.Footer-detials-box p{
  line-height: 2rem;
}
.Footer-detials-box  {
  position: relative;
  z-index: 3;
  width: 35%;
  color: var(--clr-white);
}
.Footer-detials-box  img{
  margin: 3% 0%;
}
.Footer-detials {
  width: 100%;
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: space-evenly;
  color: var(--clr-white);
  align-items: end;
}
.Footer-detials-box2 {
  height: 15rem;
  position: relative;
  z-index: 3;
  margin-top: 6%;
  padding: 0% 2%;
}
.Footer-detials-box2-some {
  position: relative;
  z-index: 3;
}
.Footer-detials-box2-some p a {
  text-decoration: none;
  color: var(--clr-white);
}
.Footer-detials-box2-some p {
  position: relative;
  z-index: 3;
  margin-top: 9%;
}
.Footer-logo {
  position: relative;
  z-index: 3;
  margin-top: 1%;
  padding: 4% 0%;
}
.Footer-logo-image {
  position: relative;
  z-index: 3;
  float: right;
  display: flex;
  justify-content: space-between;
  width: 13%;
}
.Footer-logo-image a{
  color: black;
}
.Footer-logo-image img {
  position: relative;
  z-index: 3;
  cursor: pointer;
  border-radius: 50%;
  height: 3.5rem;
}
.Footer-logo-image img:hover {
  position: relative;
  z-index: 3;
  box-shadow: 0 0 0.5rem var(--clr-boxshadow-navbar);
}
.Footer-line{
  height: 11rem;
  width: 2px;
  background-color: white;
}
.footer-boxes {
  position: relative;
  z-index: 3;
  z-index: 4;
  display: flex;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
}
.footer-boxes-line {
  position: relative;
  z-index: 3;
  z-index: 1;
  width: 0.1%;
  height: 100%;
  background-color: var(--clr-orange);
  opacity: 0.1;
}
.footer-boxes-Y-line {
  position: relative;
  z-index: 3;
  z-index: 1;
  top: 0;
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.footer-boxes-Y-line-1 {
  position: relative;
  z-index: 3;
  z-index: 1;
  width: 100%;
  height: 0.4%;
  background-color: var(--clr-orange);
  opacity: 0.1;
}
.footer-all-grid {
  position: relative;
  z-index: 3;
  z-index: 1;
  right: 0;
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .Footer {
   flex-direction: column;
  }
  .Footer-detials{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .Footer-detials-box{
    width: 100%;
    flex-direction: column;
    display: flex;
    text-align: center;
  }
  .Footer-detials-box img{
    display: none;
  }
  .Footer-detials-box p{
    width: 100%;
    line-height: 1.5rem;
    font-size: 1rem;
    padding: 3% 0%;
  }
  .Footer-line{
    display: none;
  }
  .Footer-detials-box2{
    height: auto;
    width: 90%;
    text-align: center;
    flex-direction: column;
    display: flex;
    align-items: center;
  }
  .Footer-detials-box2 h1{
    font-size: 1.2rem;
    cursor: pointer;
  }
  .Footer-detials-box2-some{
    width: 50%;
    height: auto;
    margin-top: -1rem;
  }
  .Footer-logo{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .Footer-logo-image{
    width: 35%;
    margin-top: 2rem;
  }
  .Footer-detials-box2-some {
    display: none;
    transition: max-height 0.3s ease-out;
  }
  .Footer-detials-box2-some.open {
    display: block;
  }
  .footer-boxes {
    position: relative;
    z-index: 3;
    z-index: 4;
    display: flex;
    justify-content: space-evenly;
    height: 100%;
    width: 100%;
}
}
/* Footer.css */@import url(../../constants/colorConstans.css);
.Footer {
  padding: 2% 2%;
  background: linear-gradient(var(--clr-blue), var(--clr-orange));
  position: relative;
  z-index: 3;
}
.Footer p{
  cursor: pointer;
}
.Footer-detials-box p{
  line-height: 2rem;
}
.Footer-detials-box  {
  position: relative;
  z-index: 3;
  width: 35%;
  color: var(--clr-white);
}
.Footer-detials-box  img{
  margin: 3% 0%;
}
.Footer-detials {
  width: 100%;
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: space-evenly;
  color: var(--clr-white);
  align-items: end;
}
.Footer-detials-box2 {
  height: 15rem;
  position: relative;
  z-index: 3;
  margin-top: 6%;
  padding: 0% 2%;
}
.Footer-detials-box2-some {
  position: relative;
  z-index: 3;
}
.Footer-detials-box2-some p a {
  text-decoration: none;
  color: var(--clr-white);
}
.Footer-detials-box2-some p {
  position: relative;
  z-index: 3;
  margin-top: 9%;
}
.Footer-logo {
  position: relative;
  z-index: 3;
  margin-top: 1%;
  padding: 4% 0%;
}
.Footer-logo-image {
  position: relative;
  z-index: 3;
  float: right;
  display: flex;
  justify-content: space-between;
  width: 13%;
}
.Footer-logo-image img {
  position: relative;
  z-index: 3;
  cursor: pointer;
  border-radius: 50%;
  height: 3.5rem;
}
.Footer-logo-image img:hover {
  position: relative;
  z-index: 3;
  box-shadow: 0 0 0.5rem var(--clr-boxshadow-navbar);
}
.Footer-line{
  height: 11rem;
  width: 2px;
  background-color: white;
}
.footer-boxes {
  position: relative;
  z-index: 3;
  z-index: 4;
  display: flex;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
}
.footer-boxes-line {
  position: relative;
  z-index: 3;
  z-index: 1;
  width: 0.1%;
  height: 100%;
  background-color: var(--clr-orange);
  opacity: 0.1;
}
.footer-boxes-Y-line {
  position: relative;
  z-index: 3;
  z-index: 1;
  top: 0;
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.footer-boxes-Y-line-1 {
  position: relative;
  z-index: 3;
  z-index: 1;
  width: 100%;
  height: 0.4%;
  background-color: var(--clr-orange);
  opacity: 0.1;
}
.footer-all-grid {
  position: relative;
  z-index: 3;
  z-index: 1;
  right: 0;
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .Footer {
   flex-direction: column;
  }
  .Footer-detials{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .Footer-detials-box{
    width: 100%;
    flex-direction: column;
    display: flex;
    text-align: center;
  }
  .Footer-detials-box img{
    display: none;
  }
  .Footer-detials-box p{
    width: 100%;
    line-height: 1.5rem;
    font-size: 1rem;
    padding: 3% 0%;
  }
  .Footer-line{
    display: none;
  }
  .Footer-detials-box2{
    height: auto;
    width: 90%;
    text-align: center;
    flex-direction: column;
    display: flex;
    align-items: center;
  }
  .Footer-detials-box2 h1{
    font-size: 1.2rem;
    cursor: pointer;
  }
  .Footer-detials-box2-some{
    width: 50%;
    height: auto;
    margin-top: -1rem;
  }
  .Footer-logo{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .Footer-logo-image{
    width: 35%;
    margin-top: 2rem;
  }
  .Footer-detials-box2-some {
    display: none;
    transition: max-height 0.3s ease-out;
  }
  .Footer-detials-box2-some.open {
    display: block;
  }
  .footer-boxes {
    position: relative;
    z-index: 3;
    z-index: 4;
    display: flex;
    justify-content: space-evenly;
    height: 100%;
    width: 100%;
}
}