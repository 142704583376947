.RiyoLogisticsSection2 {
  display: flex;
  padding: 0% 4%;
  position: relative;
  align-items: center;
  height: 100vh;
}
.RiyoLogisticsSection2-details {
  padding: 0 1% 0 0;
  width: 60%;
  height: 100%;
  position: relative;
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
}
.RiyoLogisticsSection2-details-pagename {
  font-size: 1.3rem;
  color: var(--clr-orange);
  font-weight: bold;
}
.RiyoLogisticsSection2-details p {
  font-size: 18px;
}
.RiyoLogisticsSection2-details span {
  position: relative;
  z-index: 6;
}
.RiyoLogisticsSection2-details p {
  z-index: 5;
  position: relative;
  line-height: 2rem;
  color: var(--text-clr);
  z-index: 6;
}
.RiyoLogisticsSection2-details p:nth-child(2) {
  margin-top: 2%;
}
.RiyoLogisticsSection2-all-details {
  width: 40%;
  position: relative;
}
.RiyoLogisticsSection2-all-details-image-box,
.RiyoLogisticsSection2-all-details-image-box-2 {
  float: right;
  height: auto;
  width: 60%;
  display: flex;
  height: 100%;
  justify-content: space-between;
}
.RiyoLogisticsSection2-all-details-image-box-2 {
  float: left;
  margin-top: -20%;
}
.RiyoLogisticsSection2-all-details-image-box-details1 {
  width: 15%;
  position: relative;
}
.RiyoLogisticsSection2-all-details-image-box-image {
  width: 80%;
}

.RiyoLogisticsSection2-all-details-image-box-image img {
  height: 40vh;
  border-radius: 1rem;
  width: 100%;
  object-fit: cover;
}
.RiyoLogisticsSection2-all-details-image-box-details1-sircle,
.RiyoLogisticsSection2-all-details-image-box-details1-sircle-2 {
  height: 4.6rem;
  width: 90%;
  background: linear-gradient(var(--clr-orange), var(--clr-blue));
  border-radius: 0px 100px 100px 0;
}
.RiyoLogisticsSection2-all-details-image-box-details1-sircle-2 {
  position: absolute;
  bottom: 0;
  border-radius: 100px 0px 0px 100px;
  background: linear-gradient(var(--clr-blue), var(--clr-orange));
}
.RiyoLogisticsSection2-details-circle {
  position: absolute;
  top: -20px;
  left: -20px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  z-index: 2;
  background: linear-gradient(var(--clr-orange), var(--clr-blue));
  opacity: 0.5;
}
@media (max-width: 1024px) and (min-width: 768px) {
  .RiyoLogisticsSection2-details p {
    font-size: 15px;
    line-height: 2rem;
  }
  .RiyoLogisticsSection2-details span {
    font-size: 1rem;
  }
}

@media (max-width: 1280px) and (min-width: 1024px) {
  .RiyoLogisticsSection2-details p {
    font-size: 20px;
  }
  .RiyoLogisticsSection2-details span {
    font-size: 1.2rem;
  }
  .RiyoLogisticsSection2-all-details-image-box-image img {
    height: 40vh;
  }
}

@media (max-width: 1440px) and (min-width: 1281px) {
  .RiyoLogisticsSection2-details p {
    font-size: 20px;
  }
  .RiyoLogisticsSection2-details span {
    font-size: 1.3rem;
  }
}

@media (max-width: 1600px) and (min-width: 1440px) {
  .RiyoLogisticsSection2-details p {
    font-size: 26px;
    line-height: 2.4rem;
  }
  .RiyoLogisticsSection2-details span {
    font-size: 1.5rem;
  }
}

@media (max-width: 1800px) and (min-width: 1601px) {
  .RiyoLogisticsSection2-details p {
    font-size: 26px;
    line-height: 3rem;
  }
  .RiyoLogisticsSection2-details span {
    font-size: 1.6rem;
  }
  .RiyoLogisticsSection2-all-details-image-box-image img {
    height: 36vh;
  }
}

@media (max-width: 2000px) and (min-width: 1801px) {
  .RiyoLogisticsSection2-details p {
    font-size: 32px;
    line-height: 2.4rem;
    line-height: 3rem;
  }
  .RiyoLogisticsSection2-details span {
    font-size: 2.3rem;
  }
}

@media (max-width: 2560px) and (min-width: 2001px) {
  .RiyoLogisticsSection2-details p {
    font-size: 40px;
    line-height: 2.4rem;
    line-height: 3.8rem;
  }
  .RiyoLogisticsSection2-details span {
    font-size: 2.7rem;
  }
  .RiyoLogisticsSection2-all-details-image-box-details1-sircle,
  .RiyoLogisticsSection2-all-details-image-box-details1-sircle-2 {
    height: 7.6rem;
    width: 90%;
  }
  .RiyoLogisticsSection2-all-details-image-box-details1-sircle-2 {
    border-radius: 100px 0px 0px 100px;
  }
}



@media only screen and (max-width: 768px) {
  .RiyoLogisticsSection2 {
    padding: 3% 4%;
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .RiyoLogisticsSection2-details p {
    font-size: 19px;
    line-height: 1.6rem;
  }
  .RiyoLogisticsSection2-details span {
    font-size: 1.3rem;
  }
  .RiyoLogisticsSection2-details {
    width: 100%;
    height: 100%;
  }
  .RiyoLogisticsSection2-all-details {
    width: 100%;
    position: relative;
  }
  .RiyoLogisticsSection2-all-details-image-box-image img {
    height: 13rem;
    box-shadow: 0 0 5px var(--clr-black);
  }
  .RiyoLogisticsSection2-all-details-image-box-details1-sircle,
.RiyoLogisticsSection2-all-details-image-box-details1-sircle-2 {
  height: 4.6rem;
  width: 100%;
}
.RiyoLogisticsSection2-details-circle {
  position: absolute;
  top: -10px;
  left: -10px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  z-index: 2;
  background: linear-gradient(var(--clr-orange), var(--clr-blue));
  opacity: 0.5;
}

}
