@import url(../../constants/colorConstans.css);
.missionVision-box {
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: darkolivegreen; */
}
.missionVision-box2 {
  height: 70vh;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
   /* background-color:pink; */
}
.flip-box1 {
  height: 65vh;
  width: 35%;
  border-radius: 0.625rem;
  background-color: var(--clr-darkblue);
  clip-path: polygon(0 0, 100% 16%, 100% 86%, 0% 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.mission-details-box {
  height: auto;
  width: 90%;
  /* background-color: yellow; */
}
.mission-details-box h5 {
  font-size: 1.8rem;
  color: var(--clr-orange);
  font-family: Arial;
}
.mission-details-box p {
  font-size: 1rem;
  margin-top: 0.625rem;
  color: var(--clr-white);
  font-family: Arial;
  line-height: 1.875rem;
}
.slider-box {
  height: 90%;
  width: 30%;
  border-radius: 1.25rem;
  overflow: hidden;
  /* background-color: gold; */
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: -42px;
    left: 0;
    width: 30px;
    height: 30px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.flip-box-2 {
  height: 65vh;
  width: 35%;
  background-color: var(--clr-darkblue);
  border-radius: 0.625rem;
  display: flex;
  justify-content: center;
  clip-path: polygon(0 16%, 100% 0, 100% 100%, 0 88%);
  align-items: center;
}
.mission-details-box2 {
  height: auto;
  width: 90%;
  /* background-color: yellow; */
}
.mission-details-box2 h5 {
  font-size: 1.8rem;
  color: var(--clr-orange);
  font-family: Arial;
}
.mission-details-box2 p {
  font-size: 1rem;
  margin-top: 0.625rem;
  font-family: Arial;
  color: var(--clr-white);
  line-height: 1.875rem;
}
.slide-img {
  width: 100%;
  height: 63vh;
}


@media only screen and (max-width:600px) {
  .missionVision-box {
    height: 100%;
    width: 100%;
    flex-direction:column;
    margin-top: 4%;
  }
  .missionVision-box2 {
    height: 100vh;
    width: 90%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .flip-box1 {
    height: 35vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    clip-path: polygon(0 0, 100% 0%, 95% 100%, 5% 100%);
  }
  .mission-details-box {
    height: 35vh;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 3% 5%;
  }
  .mission-details-box h5 {
    font-size: 1.5rem;
    margin-top: 1%;
    font-size: 1.8rem;
}
.mission-details-box p{
    font-size: 0.9rem;
    font-family: Arial;
    line-height: 1.2rem;
    padding: 0% 4%;
}
.slider-box{
    height: 150vh;
    width: 90%;
    height: 50vh;
    background-size: cover;
    margin-top: 5%;
    background-position: cover;
  }
  .flip-box-2 {
    height: 50vh;
    width: 100%;
    clip-path: polygon(7% 0%, 0% 100%, 100% 100%, 93% 0%);
    margin-top: 5%;
  }
  .mission-details-box2 {
    height: 35vh;
    width: 100%;
    display: flex;
    text-align: center;
    flex-direction: column;
}
.mission-details-box2 h5{
  font-size: 1.5rem;
  margin-top: 3%;
  font-size: 1.8rem;
}
.mission-details-box2 p{
  font-size: 0.9rem;
  font-family: Arial;
  line-height: 1.2rem;
  padding: 0% 6%;
  }
  .slide-img {
    height: 100%;
    width: 100%;
  }
}

/* .... desktop responsive ...... */

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199px) {
  
  .mission-details-box p{
    font-size: 1rem;
    font-family: Arial;
    line-height: 1.3rem;
    padding: 0% 4%;
}
.mission-details-box2 p{
  font-size: 1rem;
  font-family: Arial;
  line-height: 1.3rem;
  padding: 0% 6%;
  }

}
