.HomeAboutUs {
  width: 92%;
  height: 60vh;
  display: flex;
  background-color: var(--clr-white);
  /* background-color: rgb(192, 226, 192); */
  padding: 2rem 4%;
  margin: 1% 0%;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.HomeAboutUs-image {
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.HomeAboutUs-image-red {
  height: 38%;
  width: 38%;
  top: 0;
  position: absolute;
  background-color: var(--clr-orange);
  left: 0;
}
.HomeAboutUs-image-blue {
  height: 38%;
  width: 38%;
  background-color: var(--clr-blue);
  position: absolute;
  bottom: 0;
  right: 0;
}
.HomeAboutUs-image img {
  position: absolute;
}
.HomeAboutUs-details {
  padding: 1% 2%;
  width: 60%;
}
.HomeAboutUs-details h1 {
  font-size: 3rem;
  color: var(--clr-blue);
}
.HomeAboutUs-details span {
  color: var(--clr-orange);
}
.HomeAboutUs-details button {
  background-color: var(--clr-blue);
  color: var(--clr-white);
  padding: 0.9rem 1.5rem;
  border-radius: 0.5rem;
  border: none;
  transition: 0.6s;
  /* margin-top: 2%; */
  /* float: right; */
  cursor: pointer;
}
.HomeAboutUs-details p {
  color: var(--text-clr);
  line-height: 1.5rem;
}
.HomeAboutUs-details button:hover {
  background-color: var(--clr-orange);
}
.HomeAboutUs-details_line {
  width: 20%;
  height: 8px;
  border-radius: 5px;
  background-image: linear-gradient(to right, #ee6c4d, #0c4c78);
  margin-top: -20px;
}
.img1 {
  width: 50%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 4px;
}
.img1 img {
  width: 100%;
  height: 50%;
  border-radius: 0.3125rem;
  position: absolute;
  object-fit: cover;
}
.img-wrapper {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  background-image: url('../../Asset/Home/RiyoHomeAboutimage1.jpg');
  background-size: 100% 100%;
}
.img2 {
  width: 49%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.img2 img {
  width: 100%;
  height: 100%;
  border-radius: 0.3125rem;
  position: absolute;
  object-fit: cover;
}




/*------------ mobile responsive ----------- */
@media only screen and (max-width: 768px) {
  .HomeAboutUs {
    width: 92%;
    height: auto;
    display: flex;
    background-color: var(--clr-white);
    gap: 14px;
    flex-direction: column-reverse;
}
  .HomeAboutUs-details{
    width: 100%;
    padding:  0 0;
  }
  .HomeAboutUs-details h1{
    font-size: 1.5rem;

  }
  .HomeAboutUs-details_line{
    height: 3px;
    margin-top: -14px;
  }

.HomeAboutUs-details p {
    color: var(--text-clr);
    line-height: 1.2rem;
    font-size: 1rem;
}
.HomeAboutUs-details button {
  background-color: var(--clr-blue);
  color: var(--clr-white);
  padding: 0.4rem 1rem;
  border-radius: 0.2rem;
  border: none;
  transition: 0.6s;
  /* margin-top: 2%; */
  /* float: right; */
  font-size: 8px;
  cursor: pointer;
}
.img1 {
  width: 49%;
  height: 100%;
  /* background-color: rgb(116, 10, 10); */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 4px;
}
.HomeAboutUs-image {
  /* position: relative; */
  width: 100%;
  height: 38vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* background-color: green; */
}
.img2 {
  width: 48%;
  height: 100%;
  /* background-color: rgb(243, 139, 139); */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
}

/* .... desktop responsive ...... */

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199px) {
  /* code.. */
  .HomeAboutUs-image {
    /* position: relative; */
    width: 40%;
    gap: 2%;
  }
  .img1 {
    width: 50%;
    gap: 2%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* code... */
}

@media only screen and (min-width: 1921px) {
  .HomeAboutUs-details p {
    color: var(--text-clr);
    font-size: 2.5rem;
    line-height: 4rem
}
.HomeAboutUs-details button {
  background-color: var(--clr-blue);
  color: var(--clr-white);
  padding: 1.9rem 2.5rem;
  border-radius: 0.5rem;
  border: none;
  transition: 0.6s;
  /* margin-top: 2%; */
  /* float: right; */
  cursor: pointer;
  font-size: 2rem;
}
}