@import url(../../constants/colorConstans.css);
.riyoservices-div{
    height: 55vh;
    width: 100%;
    display: flex;
    background-image: url('../../Asset//RIYOservices/WhatsApp\ Image\ 2024-05-21\ at\ 14.07.07_82e7c9c7.jpg');
    background-repeat: no-repeat;
    background-size: 100% 120%;
    justify-content: center;
    align-items: center;
    /* background-color: aqua; */
}
.riyoservices-div h1{
    font-size: 7.2rem;
    color: var(--clr-white); 
    font-family: Arial;
}

@media only screen and (max-width:600px) {
    .riyoservices-div{
        height: 35vh;
        width: 100%;
        flex-direction: column;
    }
    .riyoservices-div h1{
        font-size: 2.2rem;
    }
}