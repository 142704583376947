@import url(../../constants/colorConstans.css);
.riyoservicesmobile-div{
    height:35vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
     /* background-color: blueviolet; */
}
.riyoservicesmobil-content{
    height: 26vh;
    width: 88%;
}
.riyoservicesmobil-heading{
    font-size: 4.2rem;
    font-weight:400;
    font-family: Arial;
    color: var(  --clr-darkblue);
}
.riyoservicesmobil-line{
    height: 2%;
    width: 10%;
    border-radius: 2rem;
    margin-top: 0.6rem;
    background: linear-gradient(to left, var(--clr-blue), var(--clr-orange));
}
.comprehensive1-para{
    font-size: 1.4rem;
    color: var( --clr-black);
    margin-top: 2rem;
    font-family: Arial;
    line-height: 2rem;
}


/*------------ mobile responsive ----------- */
@media only screen and (max-width: 768px) {
    .riyoservicesmobil-line {
        height: 2%;
        width: 10%;
        border-radius: 2rem;
        margin-top: -0.9rem;
        background: linear-gradient(to left, var(--clr-blue), var(--clr-orange));
    }
    .riyoservicesmobil-heading {
        font-size: 1.2rem;

    }
    .comprehensive1-para {
        font-size: 1rem;
        color: var( --clr-black);
        margin-top: 2rem;
        font-family: Arial;
        line-height: 2rem;
    }    
}
