.HeroHomeNaBar {
  background: linear-gradient( to right ,  var(--clr-orange), var(--clr-blue) , var(--clr-blue));
  padding: 0.6rem 16%;
  /* margin: 0.5rem 0rem; */
  box-shadow: 0px 0px 5px var(--clr-Shadow);
  position: sticky;
  top: 0;
  z-index: 10;
}
.HeroHomeNaBar ul {
  justify-content: space-between;
  display: flex;
  list-style: none;
  color: var(--clr-white);
  text-align: center;
  align-items: center;
}
.HeroHomeNaBar ul li{
  cursor: pointer;
}
.HeroHomeNaBar ul li a{
  color: var(--clr-white);
  text-decoration: none;
}
.HeroHomeNaBar ul li:active{
  color: var(--clr-orange);
}
.HeroHomeNaBar-line{
  height: 2rem;
  width: 2px;
  background-color: var(--clr-white);
}
.HeroHomeNaBar ul li img{
  height: 2.5rem;
  width: 2rem;
}

@media only screen and (max-width: 600px) {
  .HeroHomeNaBar {
    display: none;
    background: linear-gradient( to right ,  var(--clr-orange), var(--clr-blue) , var(--clr-blue));
    padding: 0.6rem 16%;
    margin: 0.5rem 0rem;
    box-shadow: 0px 0px 5px var(--clr-Shadow);
    position: sticky;
    top: 0;
    z-index: 10;
  }
  .HeroHomeNaBar ul {
    justify-content: space-between;
    display: flex;
    list-style: none;
    color: var(--clr-white);
    text-align: center;
    align-items: center;
  }
  .HeroHomeNaBar ul li{
    cursor: pointer;
  }
  .HeroHomeNaBar ul li a{
    text-decoration: none;
  }
  .HeroHomeNaBar ul li:active{
    color: var(--clr-orange);
  }
  .HeroHomeNaBar-line{
    height: 2rem;
    width: 2px;
    background-color: var(--clr-white);
  }
  .HeroHomeNaBar ul li img{
    height: 2.4rem;
  }
}
