@import url(../../constants/colorConstans.css);
.riyo-surakhsha-section{
    height: 90vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.riyosurakhsha-div{
    height: 80vh;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.riyo-surakhsha-box1{
    height: 70vh;
    width: 49%;
    display: flex;
    position: relative;
}
.riyo-surakhsha-imgbox{
    height: 100%;
    width: 60%;
    background-color: var(--clr-orange);
    border-radius: 0.5rem;
}
.riyo-surakhsha-imgbox1{
    height: 90%;
    width: 95%;
    background-image: url('../../Asset//RIYOservices/RIYOserviceBussiness.jpg');
    object-fit: cover;
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    right: 0;
    bottom: 5%;
    border-radius: 0.7rem;
}


.riyo-surakhsha-box2{
    height: 80vh;
    width: 45%;
    display: flex;
    justify-content:start;
    align-items: center;
}

.riyo-services-imgbox2{
    height: 90%;
    width: 95%;
    border-radius: 1.0625rem;
    /* background-color: chartreuse; */
}
.riyo-services-imgbox2 img{
    width: 100%;
    height: 100%;
    border-radius: 1.0625rem;
    object-fit: cover;
}
.riyo-surakhsha-para{
    height: 100%;
    width: 90%;
    /* background-color: darkgoldenrod; */
}
.riyosurakhsha-circle{
    height: 5.25rem;
    width: 5.25rem;
    border-radius: 100%;
    background-image: linear-gradient(var(--clr-darkblue), var(--clr-orange));
    animation-name: animate;
    
}
@keyframes animate{
    0% {
        transform: scale(0.6) rotate(0deg);
    }
    50% {
        transform: scale(1) rotate(460deg);
    }
    100% {
        transform: scale(0.6) rotate(360deg);
        
    }
}
.riyosurakhsha-circle{
    animation: animate 3s infinite;
}
.circle-text{
  height: 21%;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.riyo-surakhsha-text{
    font-size: 3.4rem;
    color:var(--clr-darkblue);
    /* position: absolute; */
    font-family: Arial;   
}
.riyo-surakhsha-para2{
    height: 81%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;  
}
.riyo-surakhsha-para2 p{
    font-size: 1.2rem;
    color: var( --clr-black);
    font-family: Arial;
    line-height: 2rem;
}
.riyo-surakhsha-para2 button{
    height: 4.6rem;
    width: 15rem;
    background-color:var(--clr-darkblue);
    color: var( --clr-white);
    font-size: 1.4rem;
    border: none;
    font-family: Arial;
    border-radius: .3125rem;
    cursor: pointer;
    box-shadow: 0rem .3125rem .9375rem -0.1875rem var(--clr-darkblue);
}



@media (min-width: 62rem) and (max-width: 74.9375rem) {
    .riyo-surakhsha-para{
        height: 90%;
        width: 90%;
        
    }
    .riyo-surakhsha-para2 p{
        font-size: 1.4rem;
        color: var( --clr-black);
        font-family: Arial;
    
    }
    .riyo-surakhsha-para2 p{
        font-size: 1.2rem;
        color: var( --clr-black);
        font-family: Arial;
        line-height: 2rem;
    }
    .riyo-surakhsha-para2 button{
        background-color: var(--clr-darkblue);
        color: var( --clr-white);
        font-size: 1.7rem;
        border: none;
        font-family: Arial;
        border-radius: .3125rem;
    }
    .riyo-surakhsha-para2 p{
        font-size: 1rem;
        color: var( --clr-black);
        font-family: Arial;
        line-height: 2.25rem;
    }
    .riyo-surakhsha-para2 button{
        height: 40%;
        width: 29%;
        background-color:var(--clr-darkblue);
        color: var( --clr-white);
        font-size: 1.4rem;
        border: none;
        font-family: Arial;
        border-radius: .3125rem;
        cursor: pointer;
        box-shadow: 0rem .3125rem .9375rem -0.1875rem var(--clr-darkblue); 
    }
    .riyo-surakhsha-text{
        font-size: 2.8rem;
        color:var( --clr-black);
        position: absolute;
    }
    .riyosurakhsha-circle{
        height: 70%;
        width: 20%;
        border-radius: 100%;
        background-color: var(--clr-darkblue);
    }
    
  }
  
  
  @media (min-width: 75rem) {
   
  }
  
  @media only screen and (min-width: 120.0625rem) {
   
  }
  @media only screen and (max-width:600px) {
    .riyo-surakhsha-section{
        height: auto;
        width: 100%;
        padding: 5% 0%;
    }
    .riyosurakhsha-div{
        height: 100%;
        width: 90%;
        flex-direction: column-reverse;
    }
    .riyo-surakhsha-box1{
        height: 18rem;
        width: 100%;
    }
    .riyo-surakhsha-box2{
        height: 100%;
        width: 100%;
        flex-direction: column;
    }
    .riyo-surakhsha-imgbox{
        height: 100%;
        width: 60%;
    }
    .riyo-services-imgbox2{
        height: 100%;
        width: 100%;
    }
    .riyo-services-imgbox2 img{
        width: 100%;
    }
    .riyo-surakhsha-para{
        height: 100%;
        width: 100%;
    }
    .riyosurakhsha-circle{
        height: 4rem;
        width: 4rem;
    }
    .circle-text{
        height: auto;
        width: 100%;
    }
    .riyo-surakhsha-text{
        margin: 0;
        font-size: 1.7rem;
         
    }
    .riyo-surakhsha-para2{
        height: 10%;
        width: 100%;
    }
    .riyo-surakhsha-para2 p{
        font-size: 1rem;
        line-height: 1.8rem;
    }
    .riyo-surakhsha-para2 button{
        height: 8vh;
        width: 50%;
        font-size: 1.4rem;
        margin-bottom: 7%;
    }
  }
