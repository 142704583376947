.HomeOurPresence{
    width: 100%;
    height: 70vh;
    margin: 1% 0;
    padding: 2% 0;
    display: flex;
    background-image: url("../../Asset/Home/bg-grid.png");
    justify-content: space-around;
    background-size: 100% 100%;
    align-items: center;
}
.HomeOurPresence-box{
    height: 100%;
    width: 45%;
    height: auto;
    color: white;
    position: relative;
}
.HomeOurPresence-box_line{
    width: 30%;
    height: 8px;
    border-radius: 5px;
    background-image: linear-gradient(to right, #ee6c4d, #0c4c78);
    margin-top: -40px;
}
.HomeOurPresence-box-details{
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.HomeOurPresence-box-details h1{
    font-size: 3.4rem;
    font-weight: 500;
    margin-top: -10px;
}

.HomeOurPresence-box2{
    position: relative;
    height: 100%;
    width: 45%;
    display: flex;
    justify-content: center;
}
.HomeOurPresence-box2 img{
    height: 100%;
}

/*------------ mobile responsive ----------- */
@media only screen and (max-width: 768px) {
    .HomeOurPresence{
        height: auto;
        flex-direction: column;
    }
    .HomeOurPresence-box_line {
        width: 30%;
        height: 8px;
        border-radius: 5px;
        background-image: linear-gradient(to right, #ee6c4d, #0c4c78);
        margin-top: -19px;
    }
    .HomeOurPresence-box {
        width: 90%;
        height: auto;
        color: white;
    }
    .HomeOurPresence-box2 {
        margin: 2% 0;
        width: 100%;
        height: 22rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        overflow: hidden;
    }
    .HomeOurPresence-box-details h1 {
        font-size: 2rem;
        font-weight: 500;
        margin-top: -10px;
    }
    .HomeOurPresence-box_line {
        width: 30%;
        height: 8px;
        border-radius: 5px;
        background-image: linear-gradient(to right, #ee6c4d, #0c4c78);
        margin-top: -19px;
    }
}