.section_seconds_container {
  width: 100%;
  height: 80%;
  display: flex;
  gap: 0.1%;
}
.Images_conatiner {
  width: 35%;
  display: flex;
  justify-content: end;
  justify-content: center;
  align-items: center;
}
.image_wrapper {
  width: 95%;
  display: flex;
}
.img-div {
  margin-top: 35%;
  height: 20rem;
  width: 100%;
  background-size: 100% 100%;
  background-image: url("../../Asset/RiyoBusiness/RiyoBusiness2.jpeg");
}
.images {
  width: 100%;
  height: 200px;
  border-radius: 5px;
}
.image {
  margin-top: 30px;
}
.active_image {
  width: 90%;
  height: 230px;
  margin-left: 10%;
  border-radius: 0.3125rem;
}

.top_wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Logo_Conatiner {
  width: 108px;
  height: 108px;
  border-radius: 5px;
  background-color: #0c4c78;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.Logo_container_wrappe {
  width: 108px;
  height: 108px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--clr-blue);
  border-radius: 0.7rem;
}
.Logo_container_wrappe img {
  position: absolute;
}
.l_w_p {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.p_logo_25 {
  text-align: center;
  color: white;
}
.p_logo_25 span {
  color: #ee6c4d;
  text-align: center;
  font-weight: 700;
  font-size: 1.1rem;
}

/* text Conatiner */

.Text_conatiner {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.textitems {
  margin-top: 10%;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.textitems img {
  width: 100px;
  height: 70px;
}
.textitems_text {
  width: 80%;
  height: auto;
  /* background-color: green; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.textitems_text p {
  font-size: 30px;
  font-weight: 700;
  font-family: "Arial";
  margin-top: -10px;
  color: #0c4c78;
}

.line {
  width: 30%;
  height: 8px;
  border-radius: 5px;
  background-image: linear-gradient(to right, #ee6c4d, #0c4c78);
  margin-top: -25px;
}
.text_conatiner {
  width: 100%;
  height: auto;
}
.text_conatiner p {
  font-size: 1.25rem;
  font-family: "Arial";
}
.text_conatiner p a {
  color: #ee6c4d;
  font-weight: 700;
}
.Design_pattern_cotainer {
  width: 20%;
  height: 70vh;
  /* background-color: rgb(172, 234, 143); */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  overflow: hidden;
}
.pattern_1 {
  width: 400px;
  height: 400px;
  border-radius: 30px;
  rotate: 45deg;
  background-color: #f5ad9b;
  /* box-shadow: 0px 2px 10px 0px #00000040; */
  position: relative;
  right: -148px;
  /* display: flex;
   justify-content: center;
  align-items: center; */
  padding: 0rem 3.5rem;
}
.pattern_2 {
  width: 350px;
  height: 350px;
  border-radius: 30px;
  rotate: 90deg;
  background-color: #0c4c78;
  /* box-shadow: 0px 2px 10px 0px #00000040; */
  right: -20px;
}

/*------------ mobile responsive ----------- */
@media only screen and (max-width: 768px) {
  .section_seconds_container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column-reverse;
    gap: 0.1%;
    position: relative;
    padding: 1rem 0;
  }
  .Text_conatiner {
    width: 100%;
    height: auto;
  }

  .line {
    margin-top: -7px;
  }
  .textitems_text p {
    font-size: 18px;
  }
  .textitems {
    padding: 2% 0;
    width: 95%;
    gap: 2rem;
  }
  .text_conatiner {
    width: 95%;

    position: relative;
    z-index: 1;
  }
  .img-div{
    margin: 0;
    margin: 5% 0 0 0;
  }
  .text_conatiner p {
    margin: 0;
    font-size: 0.7rem;
    font-family: "Arial";
    line-height: 1.4rem;
  }
  .Images_conatiner {
    width: 100%;

    height: auto;
  }
  .image_wrapper {
    width: 76%;
    grid-template-columns: repeat(auto-fit, minmax(118px, 1fr));
  }
  .Design_pattern_cotainer {
    width: 100%;
    height: 70vh;
    overflow: hidden;
    position: absolute;
    top: 54px;
  }
  .pattern_1 {
    width: 400px;
    height: 400px;
    border-radius: 30px;
    rotate: 45deg;
    background-color: #f5ad9b;
    position: relative;
    right: -307px;
    padding: 0rem 3.5rem;
  }
  .pattern_2 {
    width: 350px;
    height: 350px;
    border-radius: 30px;
    rotate: 90deg;
    background-color: #0c4c78;
    /* box-shadow: 0px 2px 10px 0px #00000040; */
    right: -20px;
  }
  .active_image {
    margin-top: 20%;
    height: 131px;
    border-radius: 0.3125rem;
  }
  .images {
    /* width: 127px; */
    height: 131px;
    border-radius: 5px;
  }
}

/* .... desktop responsive ...... */

/* Large devices (desktops, 992px and up) */
@media (min-width: 768px) and (max-width: 1024px) {
  .img-div {
    margin-top: 40%;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .img-div {
    margin-top: 30%;
  }
}
@media (min-width: 1281px) and (max-width: 1440px) {
  .img-div {
    margin-top: 36%;
  }
}
@media (min-width:  1440px) and (max-width: 1500px) {
  .img-div {
    margin-top: 20%;
  }
}
@media (min-width:  1500px) and (max-width: 1512px) {
  .img-div {
    margin-top: 8%;
  }
}
@media (min-width:  1513px) and (max-width: 1600px) {
  .img-div {
    margin-top: 20%;
  }
}
@media (min-width: 1601px) and (max-width: 1800px) {
  .img-div {
    margin-top: -5%;
   
  }
}
@media (min-width: 1801px) and (max-width: 2000px) {
  .img-div {
    margin-top: 0%;
  }
}
@media (min-width: 2000px) and (max-width: 2560px) {
  .img-div {
    margin-top: -20%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .image_wrapper {
    width: 95%;
    height: auto;
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 12px;
    column-gap: 1%;
    row-gap: 1%;
    position: relative;
  }
  .text_conatiner p {
    font-size: 1rem;
    text-align: center;
  }
  .textitems_text p {
    font-size: 28px;
  }
  .pattern_1 {
    right: -96px;
  }
}

@media (min-width: 1200px) {
}

@media only screen and (min-width: 1921px) {
  .image_wrapper {
    width: 95%;
    height: auto;
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 12px;
    column-gap: 1%;
    position: relative;
  }
  .Logo_Conatiner {
    width: 137px;
    height: 137px;
    border-radius: 10px;
  }
  .Logo_Conatiner img {
    width: 120px;
    height: 120px;
  }
  .p_logo_25 span {
    font-size: 2.1rem;
  }
  .textitems img {
    width: 135px;
    height: 136px;
  }
  .textitems_text p {
    font-size: 45px;
  }
  .text_conatiner p {
    font-size: 1.5rem;
    font-family: "Arial";
  }
  .pattern_1 {
    width: 600px;
    height: 600px;
    border-radius: 30px;
    rotate: 45deg;
    background-color: #f5ad9b;
    position: relative;
    right: -185px;
    padding: 0rem 5.5rem;
  }
  .pattern_2 {
    width: 500px;
    height: 500px;
    border-radius: 30px;
    rotate: 90deg;
    background-color: #0c4c78;
    right: 48px;
  }
}
