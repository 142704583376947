
@import url(../../constants/colorConstans.css);

.register-div{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.25rem;
}
.register-line{
    height: 5%;
    width: 10%;
    border-radius: .3125rem;
    background: linear-gradient(to left, var(--clr-blue), var(--clr-orange));
    /* background-color: brown; */
}


@media only screen and (max-width:600px) {
    .register-div{
        height: auto;
        width: 100%;
        margin-top: 0rem;
    }
    .register-div h4{
        font-size: 2rem;
    }
}