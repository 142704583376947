.HeroHomeOurservice {
  background-color: var(--clr-white);
  text-align: center;
  padding: 2% 4%;
  margin-top: 1%;
  background: linear-gradient(var(--clr-blue),var(--clr-blue), var(--clr-orange));
  color: var(--clr-white);
  position: relative;
}
.HeroHomeOurservice-all-details {
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.HeroHomeOurservice h1 {
  margin: 0;
  font-size: 4rem;
  font-weight: 400;
  padding: 0;
}
.OurServices-details {
  text-align: start;
  width: 70%;
  height: 100%;
  position: relative;
  padding-bottom: 1%;
}
.HeroHomeOurservice-bg {
  width: 25.6875rem;
  height: 34.625rem;
  position: absolute;
  right: 0;
  height: 94%;
  opacity: 0.3;
  z-index: 1;
}
.OurServices-details-Logo {
  text-align: start;
  width: 100%;
}
.OurServices-details-Logo p {
  position: relative;
  z-index: 2;
  line-height: 1.6rem;
}
.OurServices-line{
  width: 30%;
  height: 8px;
  border-radius: 5px;
  background-image: linear-gradient(to right, #ee6c4d, #0c4c78);

}
.OurServices-details-Logo button {
  margin-top: 7%;
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
  border: none;
  background-color: var(--clr-blue);
  color: var(--clr-white);
  cursor: pointer;
  transition: 0.4s;
  cursor: pointer;
  position: relative;
  z-index: 2;
}
.OurServices-details-Logo button:hover {
  background-color: var(--clr-orange);
}


.HeroHomeOurservice-scrollbar {
  margin-top: 1%;
  display: flex;
  width: 50%;
}
.HeroHomeOurservice-scrollbar-box {
  margin-top: 1rem;
  width: 55%;
  height: 6.6rem;
  display: flex;
  background-color: var(--white-transparent);
  backdrop-filter: blur(20px);
  align-items: center;
  border-radius: 1rem;
  font-size: 1.5rem;
  position: relative;
}
.HeroHomeOurservice-scrollbar-box p {
  margin: 3rem 0rem;
}
.HeroHomeOurservice-scrollbar-box button {
  padding: 0rem 0.2rem;
  position: absolute;
  height: 100%;
  width: 20%;
  right: 0;
  border-radius: 1rem;
  border: none;
  line-height: 1.5rem;
  color: var(--clr-white);
  background-color: var(--clr-orange);
  transition: 0.8s;
  cursor: pointer;
}
.HeroHomeOurservice-scrollbar-box button:hover {
  width: 100%;
  background: linear-gradient(var(--clr-blue), var(--clr-orange));
}

.HeroHomeOurservice-scrollbar-box img {
  margin: 0% 5%;
}
.logos_conatiner_logo{
    width: 70%;
    height: 100%;
    /* background-color: rgb(186, 249, 186); */
    display: flex;
    gap: 5px;
}
.logos_items{
    width: 30%;
    height: 100%;
    /* background-color: rgb(154, 154, 235); */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.line_middle{
    width: 2%;
    height: 100%;
    background-color: white;
}
.upper_logos{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.logos_items_text{
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.logo_box_logo{
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: #0C4C78;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 10px 0px #00000040;
}
.logo_box_logo img{
  width: 20px;
  height: 20px;
}
.name_logos{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
}
.name_logos p{
    text-align: start;
    font-size: 23px ;
    color: white;
    font-weight: 700;
}

.logo-div {
  position: absolute;
  top: 30%;
  right: 3%;
  display: flex;
  flex-direction: column;
}
.logo-div-1 {
  position: relative;
  top: -1rem;
}
.FaLongArrowAltDown-1 {
  position: relative;
  margin-top: -3%;
}


/*------------ mobile responsive ----------- */
@media only screen and (max-width: 768px) {
  .HeroHomeOurservice-all-details {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.OurServices-details {
  /* width: 60%; */
  /* margin-top: 3%;
  text-align: start; */
  width: 100%;
  /* position: relative; */
  /* background-color: red; */
}
.HeroHomeOurservice h1 {
  font-size: 2rem;
  font-weight: 400;
}




/* ------------------------------------------------------------------------ */
.HeroHomeOurservice-scrollbar {
 width: 100%;
 height: auto;
 /* background-color: green; */
 display: flex;

}
.logos_conatiner_logo{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.logos_items{
   width: 100%;
   height: 10vh;
   /* background-color: rgb(239, 135, 135); */
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
 
}
.line_middle{
   width: 85%;
   height: 3px;
   background-color: white;
}
.upper_logos{
 width: 90%;
 height: auto;
 display: flex;
 position: absolute;
 justify-content: space-between;
 align-items: center;
 flex-direction: row;
}
.logos_items_text{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
}
.logo_box_logo{
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: #0C4C78;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px 0px #00000040;
}
.logo_box_logo img{
  width: 20px;
  height: 20px;
}

.name_logos p{
  text-align: center;
  font-size: 18px ;
  color: white;
  font-weight: 400;
}
.HeroHomeOurservice-bg{
  width: 20.6875rem;
  height: 62%;
  position: absolute;
  right: 0;
  opacity: 0.3;
  z-index: 1;
}
.OurServices-details-Logo p {

  text-align: center;
}


}
