@import url(../../constants/colorConstans.css);
.riyo-business-section{
    height: 86vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.riyobusiness-div{
    height: 75vh;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.riyo-business-box1{
    height: 70vh;
    width: 43%;
    display: flex;
    position: relative;
}
.riyo-business-box2{
    height: 75vh;
    width: 50%;
    display: flex;
    justify-content: start;
    align-items: center;
}
.riyo-business-imgbox{
    height: 100%;
    width: 60%;
    background-color: var(--clr-orange);
    border-radius: 0.5rem;
}
.riyo-business-imgbox2{
    height: 90%;
    width: 95%;
    background-image: url('../../Asset//RIYOservices/RIYOserviceBussiness.jpg');
    object-fit: cover;
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    right: 0;
    bottom: 5%;
    border-radius: 0.7rem;
}
.riyo-business-para{
    height: 90%;
    width: 90%;
}
.business-circle{
    height: 5.25rem;
    width: 5.25rem;
    border-radius: 100%;
    background-image: linear-gradient(var(--clr-darkblue), var(--clr-orange));
    animation-name: animate;
}
@keyframes animate{
    0% {
        transform: scale(0.6) rotate(0deg);
    }
    50% {
        transform: scale(1) rotate(460deg);
    }
    100% {
        transform: scale(0.6) rotate(360deg);
        
    }
}

.business-circle{
    animation: animate 3s infinite;
}
.businesscircle-text{
  height: 21%;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  /* background-color: khaki; */
}
.riyo-business-text{
    font-size: 3.7rem;
    color:var(--clr-darkblue);
    font-family: Arial;
}
.riyo-business-para2{
    height: 84%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: var( --clr-black);
     /* background-color: brown; */
    
}

.riyo-business-para2 p{
    font-size: 1.2rem;
    color: var( --clr-black);
    font-family: Arial;
    line-height: 2rem;

}
.span-color{
    color: var(--clr-orange);
}
.riyo-business-para2 button{
    height: 4.6rem;
    width: 15rem;
    background-color:var(--clr-darkblue);
    color: var( --clr-white);
    font-size: 1.4rem;
    border: none;
    cursor: pointer;
    font-family: Arial;
    border-radius: .3125rem;
    box-shadow: 0rem .3125rem .9375rem -0.1875rem var(--clr-darkblue);

}



@media only screen and (max-width:600px) {
    .riyo-business-section{
        height: auto;
        width: 100%;
        padding: 5% 0%;
    }
    .riyobusiness-div{
        height: 100%;
        width: 90%;
        flex-direction: column-reverse;
    }
    .riyo-business-box1{
        height: 18rem;
        width: 100%;
        background-size: 100% 100%;
    }
    .riyo-business-box2{
        height: 100%;
        width: 100%;
        flex-direction: column;
    }
    
    .riyo-business-imgbox2 img{
        width: 100%;
    }
    .riyo-business-para{
        height: 100%;
        width: 100%;
    }
    .business-circle{
        height: 4rem;
        width: 4rem;
    }
    .businesscircle-text{
        height: auto;
        width: 100%;
    }
    .riyo-business-text{
        font-size: 1.5rem;
    }
    .riyo-business-para2{
        height: 10%;
        width: 100%;
        
    }
    .riyo-business-para2 p{
        font-size: 1rem;
        line-height: 1.8rem;
    
    }
    .riyo-business-para2 button{
        height: 8vh;
        width: 50%;
        font-size: 1.4rem;
    }
  }
