@import url(../../constants/colorConstans.css);
.riyo-log-section{
    height: 86vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.riyolog-div{
    height: 75vh;
    width: 94%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.span-at{
    color: var(--clr-darkblue);
    font-weight: 800;
}
.span-riyo{
    font-weight: 800;
    color: var(--clr-orange);
}

.riyo-services-box1{
    height: 70vh;
    width: 45%;
    display: flex;
    position: relative;
}
.riyo-services-imgbox{
    height: 100%;
    width: 60%;
    background-color: var(--clr-orange);
    border-radius: 0.5rem;
}
.riyo-services-box2{
    height: 75vh;
    width: 50%;
    display: flex;
    justify-content: end;
    align-items: center;
}

.riyo-services-imgbox2{
    height: 90%;
    width: 95%;
    background-image: url('../../Asset//RIYOservices/RIYOserviceLogisctics.jpg');
    position: absolute;
    right: 0;
    bottom: 5%;
    border-radius: 0.7rem;
    object-fit: cover;
    background-position: center;
    background-size: 100% 100%;

}
.riyo-services-para{
    height: 90%;
    width: 90%;
    /* background-color: darkgoldenrod; */
}
.riyoservices-circle{
    height: 5.25rem;
    width: 5.25rem;
    border-radius: 100%;
    background-image: linear-gradient(var(--clr-darkblue), var(--clr-orange));
    animation-name: animate;
}

@keyframes animate{
    0% {
        transform: scale(0.6) rotate(0deg);
    }
    50% {
        transform: scale(1) rotate(460deg);
    }
    100% {
        transform: scale(0.6) rotate(360deg);
  
    }
}

.riyoservices-circle{
    animation: animate 3s infinite;
}
.circle-text{
  height: 21%;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.riyo-log-text{
    font-size: 3.7rem;
    color:var(--clr-darkblue);
    position: absolute;
    font-family: Arial;
    
}
.riyo-text-para2{
    height: 84%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* background-color: brown; */
    
}
.riyo-text-para2 p{
    font-size: 1.2rem;
    color: var( --clr-black);
    font-family: Arial;
    line-height: 2rem;

}
.riyo-text-para2 button{
    height: 4.6rem;
    width: 15rem;
    background-color:var(--clr-darkblue);
    color: var( --clr-white);
    font-size: 1.4rem;
    border: none;
    border-radius: .3125rem;
    cursor: pointer;
    box-shadow: 0rem .3125rem .9375rem -0.1875rem var(--clr-darkblue);

}

@media only screen and (max-width:600px) {
    .riyo-log-section{
        height: auto;
        width: 100%;
        flex-direction: column;
        padding: 5% 0%;
    }
    .riyolog-div{
        height: 100%;
        width: 100%;
        flex-direction: column;
    }
    .riyo-services-box1{
        width: 90%;
        height: 15rem;
    }
    .riyo-services-imgbox{
        height: 110%;
    }
    .riyo-services-imgbox2{
       position: absolute;
        bottom: -5%;
        left: 3%;
        
    }
    .riyo-services-box2{

        height: auto;
        width: 100%;
        flex-direction: column;
    }
    .riyo-services-para{
        margin-top: 10%;
        height: auto;
        width: 90%;
    }
    .riyoservices-circle{
        height: 4rem;
        width: 4rem;
    }    
    .circle-text{
      height: auto;
      width: 100%;
    }
    .riyo-log-text{
        margin: 0;
        font-size: 1.5rem;
        position: relative;
    }
    .riyo-text-para2{
        height: 100%;
        width: 100%;
    }
    .riyo-text-para2 p{
        font-size: 1rem;
        line-height: 2rem;
    }
    .riyo-text-para2 button{
        height: 8vh;
        width: 50%;
        font-size: 1.4rem;
    }
  }



@media (min-width: 992px) and (max-width: 1199px) {
    .riyo-services-para{
        height: 90%;
        width: 90%;
        /* background-color: darkgoldenrod; */
        
    }
    .riyo-text-para2 p{
        font-size: 1.2rem;
        color: var( --clr-black);
        font-family: Arial;
    
    }
    .riyo-text-para2 button{
        height: 14%;
        width: 38%;
        background-color: var(--clr-darkblue);
        color: var( --clr-white);
        font-size: 1.7rem;
        border: none;
        border-radius: 5px;
        /* font-family: Arial; */
    }
    
    .riyo-log-text{
        font-size: 3.2rem;
        color:var( --clr-black);
        position: absolute;
        font-family: Arial;
    }
    .riyoservices-circle{
        height: 70%;
        width: 20%;
        border-radius: 100%;
        background-color: var(--clr-darkblue);
    }
    .riyo-business-para2 button{
        height: 20%;
        width: 29%;
        color: var( --clr-white);
        font-size: 1.4rem;
        border: none;
        cursor: pointer;
        font-family: Arial;
        background-color:red;
        border-radius: .3125rem;
        box-shadow: 0rem .3125rem .9375rem -0.1875rem var(--clr-darkblue);

    }
    .riyo-text-para2 p{
        font-size: 1.2rem;
        color: var( --clr-black);
        font-family: Arial;
        line-height: 2.25rem;
    
    }
    
  }
  
  
  @media (min-width: 75rem) {
   
  }
  
  @media only screen and (min-width: 120.0625rem) {
   
  }



  