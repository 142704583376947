.home-company-corner_container {
  width: 100%;
  height: 80vh;
  display: flex;
}
.pattern_container {
  width: 20%;
  height: 80vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.HomeCompanyCorner_pattern_1 {
  width: 400px;
  height: 400px;
  border-radius: 30px;
  rotate: 45deg;
  background-color: #f5ad9b;
  position: relative;
  left: -200px;
  padding: 0rem 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.HomeCompanyCorner_pattern_2 {
  width: 300px;
  height: 300px;
  border-radius: 30px;
  rotate: 90deg;
  background-color: #0c4c78;
  left: -20px;
}
/* card */
.cards_container {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.card_text{
  width: 100%;
  height: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2%;
}
.card_text_1 {
  width: 50%;
  height: auto;
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.card_text_1 {
  color: #0c4c78;
  font-family: "Arial";
  font-weight: 700;
  margin-top: -10px;
}

.card_text_1_line {
  width: 40%;
  height: 8px;
  border-radius: 5px;
  background-image: linear-gradient(to right, #ee6c4d, #0c4c78);
  margin-top: -10px;
}
.cards_container_card {
  margin-top: 0.4rem;
  width: 100%;
  height: 29.3125rem;
  display: flex;
  justify-content: space-between;
  align-items: end;
  position: relative;
  display: none;
}
.card_1 {
  width: 298px;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3%;
  position: relative;
}
.card_box {
  width: 286px;
  height: 280px;
  border-radius: 0.3125rem;
  box-shadow: 0px 1px 4px 0px #00000040;
}
.bg-card {
  width: 11.75rem;
  height: 11.75rem;
  border-radius: 0.3125rem;
  background-color: #ee6c4d4d;
  overflow: hidden;
}
.center_card {
  width: 286px;
  height: 280px;
  /* background-color: rgba(255, 0, 0, 0.227); */
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
}
.bg-card{
  object-fit: cover;
}
.bg-card img {
  width: 11.75rem;
  height: 11.75rem;
  background-size: 100% 80%;
  
}

.text_card {
  margin-top: 3%;
  width: 285px;
  height: 150px;
}

.text_card_1 {
  width: 100%;
  height: 2.5rem;
  display: flex;
  /* justify-content: ; */
  gap: 1.25rem;
  align-items: center;
  /* background-color: red; */
}
.text_card_1 p {
  font-size: 24px;
  font-weight: "Arial";
  font-weight: 400;
  color: #505050;
}
.text_card_1 span {
  color: #ee6c4d;
  font-size: 14px;
  font-size: "Arial";
  font-weight: 700;
}
.text_card p {
  font: 0.75rem;
  font-family: "Arial";
  font-weight: 400;
  /* margin-top: 1px; */
}

.card_2 {
  width: 298px;
  height: 90%;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3%;
}

.card_box2 , .card_1{
  width: 286px;
  height: 280px;
  border-radius: 0.3125rem;
  box-shadow: 0px 1px 4px 0px #00000040;
  display: flex;
  justify-content: end;
  align-items: end;
}
.card_1{
  display: block;
  justify-content: start;
  align-items: start;
}
.bg-card2 , .bg-orange {
  width: 11.75rem;
  height: 11.75rem;
  border-radius: 0.3125rem;
  background-color: #0c4c784d;
  overflow: hidden;
}
.bg-orange{
  background-color: var(--orange-tranparent);
}
.card_3 {
  width: 298px;
  height: 90%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3%;
}
.slider-container{
  width: 70%;
}
/*------------ mobile responsive ----------- */
@media only screen and (max-width: 768px) {

.home-company-corner_container {
    width: 100%;
    height: 80vh;
    display: flex;
    /* background-color: #acf8ba; */
    align-items: center;
    position: relative;
}
.cards_container{
  display: none;
}
.slider-container{
  display: block;
  width: 86%;
  height: auto;
}
.card_box2 {
  width: 290px;
  /* gap: 0.5rem; */
}

.bg-card img ,.bg-card ,.bg-card2{
  
  width: 10.75rem;
  height: 10.75rem;
  object-fit: cover;
}
.text_card {
  width: 240px;
  
}
.slick-dots li button:before {
  width: 100% !important;
  font-family: 'slick';
  font-size: 10px !important;
  line-height: 20px;
  position: absolute;
  top: 0px !important;
    left: -2rem !important;
  width: 10px;
  height: 10px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.HomeCompanyCorner_pattern_1 {
  width: 300px;
  height: 300px;
  border-radius: 30px;
  rotate: 45deg;
  background-color: #f5ad9b;
  position: relative;
  left: -204px;
  padding: 1rem 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.HomeCompanyCorner_pattern_2 {
  width: 340px;
  height: 340px;
  border-radius: 30px;
  rotate: 90deg;
  background-color: #0c4c78;
  left: -81px;
}
.card_text_1 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 30px;
  left: 0;
}
.card_text_1 h1{
  font-size: 32px;
}
.card_text_1_line{
  height: 5px;
  margin-top: -20px;
}
}








































/* .... desktop responsive ...... */

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199px) {
  /* code.. */
  .text-line_conatiner {
    width: 53%;
  }
  .HomeAouImpact-detail-2 {
    display: flex;
    text-align: center;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin-top: -4rem;
  }
  .cards_container {
    width: 80%;
  }
  .center_card {
    width: 15rem;
  }
  .text_card {
    width: 15rem;
  }
  .card_1 {
    width: 15rem;
  }
  .card_box2 {
    width: 15rem;
  }
  .card_box {
    width: 15rem;
  }
  .text_card {
    width: 15rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* code... */
}
@media only screen and (min-width: 1921px) {
  .HomeCompanyCorner_pattern_1 {
    width: 500px;
    height: 500px;
    border-radius: 30px;
    rotate: 45deg;
    background-color: #f5ad9b;
    /* box-shadow: 0px 2px 10px 0px #00000040; */
    position: relative;
    left: -264px;
    /* z-index: 1; */
    padding: 0rem 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .HomeCompanyCorner_pattern_2 {
    width: 400px;
    height: 400px;
    border-radius: 30px;
    rotate: 90deg;
    /* background-color: #0c4c78; */
    /* box-shadow: 0px 2px 10px 0px #00000040; */
    left: -20px;
  }
  .cards_container_card {
    width: 100%;
    height: 37.3125rem;
    /* background-color: rgb(233, 233, 152); */
    justify-content: space-around;
  }
  .cards_container {
    width: 70%;
    height: 80vh;
    /* background-color: rgb(199, 159, 236); */
    justify-content: center;
  }
  .card_text_1 h1 {
    font-size: 5rem;
  }
  .text_card p {
    font-size: 23px;
  }
  .text_card p {
    font: 0.75rem;
    font-family: "Arial";
    font-weight: 400;
    /* margin-top: 1px; */
    font-size: 23px;
  }
  .HomeAboutUs-details button {
    padding: 1rem 3.5rem;
  }
}
