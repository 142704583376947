.RiyoOurCollaborations {
  display: flex;
  color: var(--clr-white);
  justify-content: space-between;
  background: linear-gradient(to right, var(--clr-orange), var(--clr-blue));
  padding: 9% 4%;
  align-items: center;
}
.RiyoOurCollaborations-box {
  width: 42%;
}
.RiyoOurCollaborations h1 {
  padding: 0% 2%;
  font-size: 5rem;
  font-weight: 400;
}

.RiyoOurCollaborations-box2 {
  text-align: center;
}
.RiyoOurCollaborations-box2 h1 {
  font-weight: bold;
  padding: 0% 1%;
  font-size: 5rem;
}
.RiyoOurCollaborations-box2 h3 {
  font-weight: bold;
  margin-top: 4%;
}
.RiyoOurCollaborations-details-box h1 {
  position: relative;
  z-index: 5;
}

.RiyoOurCollaborations-allgridbox {
  position: absolute;
  top: 0;
  width: 96%;
  height: 100%;
  z-index: 2;
}

.RiyoOurCollaborations-y-all-line {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.RiyoOurCollaborations-y-line {
  opacity: 0.2;
  height: 100%;
  width: 1px;
  background-color: var(--clr-orange);
}

.RiyoOurCollaborations-x-all-line {
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.RiyoOurCollaborations-x-line {
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background-color: var(--clr-orange);
}
@media only screen and (max-width: 760px) {
  .RiyoOurCollaborations {
    flex-direction: column;
    align-items: start;
    padding: 4% 0%;
    display: grid;
    grid-template-rows: auto auto auto; /* Define three rows */
    grid-template-columns: 1fr 1fr; /* Define two columns */
    gap: 10px;
  }
  .RiyoOurCollaborations h1 {
    font-size: 2rem;
  }
  .RiyoOurCollaborations-box {
    text-align: center;
    width: 100%;
  }
  .RiyoOurCollaborations-box2{
    width: 100%;
  }
  .RiyoOurCollaborations-box2 h1{
    font-size: 3rem;
  }
  .top {
    grid-row: 1 / 2;
    grid-column: 1 / -1;
  }

  .item:nth-child(2) {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    
  }

  .item:nth-child(3) {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }

  .bottom {
    grid-row: 3 / 4;
    grid-column: 1 / -1;
    
  }
}






@media (max-width: 1024px) and (min-width: 768px) {
  .RiyoOurCollaborations h1 {
    font-size: 3.4rem;
  }
  .RiyoOurCollaborations-box2 h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 1280px) and (min-width: 1025px) {
  .RiyoOurCollaborations h1 {
    font-size: 4rem;
  }
  .RiyoOurCollaborations-box2 h1 {
    font-size: 4.4rem;
  }
}

@media (max-width: 1440px) and (min-width: 1281px) {
  .RiyoOurCollaborations h1 {
    font-size: 4rem;
  }
  .RiyoOurCollaborations-box2 h1 {
    font-size: 4.5rem;
  }
}

@media (max-width: 1599px) and (min-width: 1440px) {
  .RiyoOurCollaborations h1 {
    font-size: 4.5rem;
  }
  .RiyoOurCollaborations-box2 h1 {
    font-size: 5rem;
  }
}

@media (max-width: 1800px) and (min-width: 1600px) {
  .RiyoOurCollaborations h1 {
    font-size: 5rem;
  }
  .RiyoOurCollaborations-box2 h1 {
    font-size: 5.5rem;
  }
}

@media (max-width: 2000px) and (min-width: 1801px) {
  .RiyoOurCollaborations h1 {
    font-size: 6rem;
  }
  .RiyoOurCollaborations-box2 h1 {
    font-size: 6.5rem;
  }
}

@media (max-width: 2560px) and (min-width: 2001px) {
  .RiyoOurCollaborations h1 {
    font-size: 8rem;
  }
  .RiyoOurCollaborations-box2 h1 {
    font-size: 9rem;
  }
}
