.HomeGetTouch {
  width: 100%;
  height: 60vh;
  z-index: 4;
  display: flex;
  /* padding: 0 4rem; */
  background: linear-gradient(
    var(--clr-orange),
    var(--clr-blue),
    var(--clr-blue)
  );
  position: relative;
  justify-content: space-around;
  align-items: center;
}
.HomeGetTouch-heading {
  z-index: 4;
  width: 50%;
  height: auto;
  display: flex;
  padding: 0 2rem;
  align-items: center;
  color: var(--clr-white);
  font-size: 2rem;
  /* background-color: rgb(245, 149, 149); */
}
.HomeGetTouch-heading h1 {
  font-weight: 600;
}
.box-icon-container {
  width: 100%;
  height: 100%;
}
.HomeGetTouch-details-all {
  width: 50%;
  height: 50%;
  /* background-color: red; */
  display: flex;
  /* flex-direction: column; */
  justify-content: space-around;
  align-items: center;
}
.image-get {
  width: 500px;
  height: 100%;
  /* background-color: green; */
}
.HomeGetTouch-details {
  /* z-index: 4; */
  /* margin: 7% 0%; */
  display: flex;
  justify-content: center;
  gap: 10px;
  /* text-align: start; */
  /* background-color: green; */
}

.homeget-boxes {
  z-index: 4;
  display: flex;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
}

.homeget-boxes-line {
  z-index: 1;
  width: 0.1%;
  height: 100%;
  background-color: var(--clr-orange);
  opacity: 0.2;
}
.homeget-boxes-Y-line {
  z-index: 1;
  top: 0;
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.homeget-boxes-Y-line-1 {
  z-index: 1;
  width: 100%;
  height: 0.1%;
  background-color: var(--clr-orange);
  opacity: 0.1;
}
.homeget-all-grid {
  z-index: 1;
  right: 0;
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
}

.HomeGetTouch-details-box1 {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  /* background-color: darkblue; */
}
.box_icon {
  width: 100%;
  height: auto;
  /* background-color: green; */
  display: flex;
  gap: 1.25rem;
  align-items: center;
}
.box_icon p {
  font-size: 1.375rem;
  font-family: "Arial";
  font-weight: 400;
  color: white;
}
.icon_container_round {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  /* background-color: red; */
  background-image: linear-gradient(#df510c, #0c2c78);
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon_container_round2 {
  /* width: 0px;
  height: 50px; */
  padding: 1rem;
  border-radius: 50%;
  background-image: linear-gradient(#df510c, #0c2c78);
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon_container_round2 img {
  width: 20px;
  height: 20px;
}
.icon_container_round img {
  width: 20px;
  height: 20px;
}

/*------------ mobile responsive ----------- */
@media only screen and (max-width: 768px) {
  .HomeGetTouch {
    width: 100%;
    height: auto;
    padding: 1rem 0;

    background: linear-gradient(
      var(--clr-orange),
      var(--clr-blue),
      var(--clr-blue)
    );
    position: relative;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  }
  .icon_container_round2 {
    padding: 0.6rem;
  }
  .HomeGetTouch-heading {
    width: 77%;
    justify-content: center;
    font-size: 1.5rem;
  }
  .HomeGetTouch-details-all {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0rem 0rem;
  }
  .image-get img {
    width: 80%;
    height: 80%;
  }
  .box_icon {
    width: 100%;
    height: auto;
    display: flex;
    gap: 1.25rem;
    align-items: center;
  }
  .icon_container_round {
    width: 40px;
    height: 40px;
  }
  .box-icon-container {
    width: 100%;
    height: 100%;
    padding: 0 1rem;
  }

  .box_icon p {
    font-size: 1rem;
    font-family: "Arial";
    font-weight: 400;
    color: white;
  }
}
