.Termandconditions-data{
    padding: 1% 4%;
}
.Termandconditions-data p{
    padding: 1% 0%;
}
.Termandconditions-data ul{
    padding-left: 1.5%;
}
.Termandconditions-data ul li{
    margin-top: 0.3rem;
}