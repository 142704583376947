.RiyoLogisticsHero {
  background-image: url("../../Asset/Home/HeroSectionImage.jpeg");
  background-position: center;
  object-fit: cover;
  background-size: 100% 220%;
  height: 26rem;
}
.opecity {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.504);
  height: 100%;
}
.RiyoLogisticsHero-detail {
  height: 14rem;
  color: var(--clr-white);
  padding: 2% 3%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 80%;
  height: 45%;
  background: linear-gradient(var(--clr-blue), var(--clr-orange));
  border-radius: 1rem;
}
.RiyoLogisticsHero-detail h1 {
  font-size: 5rem;
}
.RitoLogisticsHero-heading {
  width: 60%;
}
.RiyoLogisticsHero-detail p{
  position: relative;
}
.RitoLogisticsHero-heading h1 {
  font-size: 4rem;
  position: relative;
  z-index: 5;
}
.RiyoLogisticsHeading {
  font-weight: 350;
}
.RiyoGrids {
  top: 0;
  left: 0;
  position: absolute;
  height: 80%;
  width: 15%;
}
.Riyo-y-line {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.y-line {
  height: 100%;
  width: 0.0063rem;
  background-color: var(--clr-orange);
  opacity: 0.4;
}
.Riyo-x-line {
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}
.x-line {
  height: 0.0063rem;
  width: 100%;
  background-color: var(--clr-orange);
  opacity: 0.4;
}

@media (max-width: 1200px) and (min-width: 768px) {
  .RiyoLogisticsHero-detail h1 {
    font-size: 5rem;
    margin: 0;
  }
}

@media (max-width: 1440px) and (min-width: 1200px) {
  .RiyoLogisticsHero-detail h1 {
    font-size: 5rem;
    margin: 0;
  }
  .RiyoLogisticsHero p {
    margin: 0;
  }
}

@media (max-width: 1600px) and (min-width: 1440px) {
  .RiyoLogisticsHero-detail h1 {
    font-size: 7rem;
    margin: 0;
  }
}

@media (max-width: 1800px) and (min-width: 1600px) {
  .RiyoLogisticsHero-detail h1 {
    font-size: 7rem;
    margin: 0;
  }
  .RiyoLogisticsHero p {
    margin: 0;
  }
}

@media (max-width: 2000px) and (min-width: 1800px) {
  .RiyoLogisticsHero-detail h1 {
    font-size: 8rem;
    margin: 0;
  }
  .RiyoLogisticsHero p {
    margin: 0;
  }
}

@media (max-width: 2560px) and (min-width: 2001px) {
  .RiyoLogisticsHero {
    height: 34rem;
    margin: 0;
  }
  .RiyoLogisticsHero p {
    font-size: 2rem;
    margin: 0;
  }
  .RiyoLogisticsHero-detail h1 {
    font-size: 12rem;
    margin: 0;
  }
}

@media only screen and (max-width: 760px) {
  .RiyoLogisticsHero-detail h1 {
    font-size: 2rem;
  }
  .RiyoLogisticsHero-detail p{
    font-size: 10px;
    margin: 0;
  }
  .RiyoLogisticsHero {
    height: 24rem;
  }
  .RiyoLogisticsHero-detail {
    padding: 2% 3%;
    width: 80%;
    height: 25%;
  }
  .RiyoLogisticsHero-detail p{
    position: relative;
    top: -1.5rem;
    font-size: 10px;
  }
}