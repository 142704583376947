.Privcypolicy_container{
    width: 90%;
    height: auto;
    padding: 2rem;
}
.Privcypolicy_container p{
    padding: 0.625rem 0;
    font-family: Arial;
}
.Privcypolicy_container h1{
    padding: 0.625rem;
}
.Privcypolicy_container ul {
    padding: 0 5rem 0 1.5rem;
}
.Privcypolicy_container ul li{
    padding: 0.425rem;
}
.Privcypolicy_container p b{
    color: #ED6C4D;
}

/*------------ mobile responsive ----------- */
@media only screen and (max-width: 768px) {
    .Privcypolicy_container p{
        padding: 0.625rem 0;
        font-family: Arial;
    }
    .Privcypolicy_container{
        width: 90%;
        height: auto;
        padding: 0 1rem;
    }
}