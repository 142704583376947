@import url(../../constants/colorConstans.css);
.HeaderNaBar {
  background: linear-gradient(to left, var(--clr-blue), var(--clr-orange));
  padding: 0 4%;
  position: relative;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 30;
  margin: 0;
}
.toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}
.toggle span {
  background: var(--clr-white);
  border-radius: 10px;
  display: block;
  height: 4px;
  margin: 5px 0;
  width: 25px;
}
.HeaderNaBar ul {
  display: flex;
  list-style: none;
  color: var(--clr-white);
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
.HeaderNaBar ul li {
  cursor: pointer;
  align-items: center;
  display: flex;
  margin: 0;
}
.HeaderNaBar ul li a {
  text-decoration: none;
  color: var(--clr-white);
}
.HeaderNaBar ul li img {
  height: 50px;
}
.HeaderNaBar ul li:active {
  color: var(--clr-blue);
}
.HeaderNaBar-line {
  height: 4rem;
  width: 2px;
  background-color: var(--clr-white);
}
@media (min-width:2000px ) and (max-width:2560px){
  .HeaderNaBar ul li{
    font-size: 2rem;
  }
  .HeaderNaBar-line {
    height: 5rem;
  }
}
@media (max-width: 768px) {
  .toggle {
    top: 0;
    display: block;
    width: 100%;
  }
  .HeaderNaBar {
    height: 6vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .toggle img {
    float: right;
  }
  .HeaderNaBar ul {
    flex-direction: column;
    position: absolute;
    top: 80%;
    left: 0;
    background: linear-gradient(to left, var(--clr-blue), var(--clr-orange));
    width: 100%;
    display: none;
    padding: 0;
    z-index: 5;
  }
  .HeaderNaBar ul.open {
    display: flex;
  }
  .HeaderNaBar ul li {
    justify-content: center;
    padding: 10px 0;
  }

  .HeaderNaBar ul {
    flex-direction: column;
    position: absolute;
    top: 80%;
    left: 0;
    background: linear-gradient(to left, var(--clr-blue), var(--clr-orange));
    width: 100%;
    display: none;
    padding: 0;
    z-index: 5;
  }

  .HeaderNaBar ul.open {
    display: flex;
  }

  .HeaderNaBar ul li {
    justify-content: center;
    padding: 10px 0;
  }

  /* .HeaderNaBar-line {
    display: none;
  } */



/* aaa */

}
 
