@import url(../../constants/colorConstans.css);

.contact-us-div1 {
  width: 100%;
  height: 92vh;
  display: flex;
  background-image: url("../../image/contactus.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  justify-content: center;
  align-items: center;
  /* background-color: var( --clr-white); */

}

.contact-us-div2 {
  width: 95%;
  height: 80vh;
  background-color: #0c4d78e8;
  display: flex;
  justify-content: end;
  align-items: center;
}

.container-1 {
  width: 97%;
  height: 70vh;
  display: flex;
  justify-content: space-between;
  /* background-color: red; */
}

.conatiner-text {
  width: 65%;
  height: 60vh;
  /* background-color: green;    */
}

.container-box {
  width: 100%;
  height: 70vh;
  /* background-color:blueviolet; */
}

.container-box p {
  font-size: 1.5rem;
  color: var(--clr-orange);
}

.container-box h1 {
  font-size: 2.9rem;
  color: var(--clr-white);
}

.input-box {
  height: 49vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
    /* background-color: black; */
}

.input-box input {
  height: 7vh;
  width: 50%;
  font-size: 1.4rem;
  color: var(--clr-black);
  border-radius: 0.4rem;
  border: none;
  outline: 0;
  padding: 0rem 1rem;
}

.input-1 input[type="text"]:hover {
  background-color: var(--clr-orange);
  color: white;
}

.input-1 {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: start;
  gap: 1.25rem;
   /* background-color: chartreuse; */
}

.text-area-conatiner {
  width: 100%;
  height: auto;
  /* background-color:chartreuse ; */
}

.text-area-conatiner textarea:hover {
  background-color: var(--clr-orange);
  color: var(--clr-white);
}

.text-area-conatiner textarea {
  width: 100%;
  height: 20vh;
  border-radius: 0.4rem;
  border: none;
  outline: none;
  font-size: 1.4rem;
  padding: 0.2rem 0;
}

.button-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container button {
  width: 9.375rem;
  height: 3.125rem;
  border-radius: 0.4rem;
  border: none;
  font-size: 1.4rem;
  color: var(--clr-orange);
}

.button-container button:hover {
  background-color: var(--clr-orange);
  color: var(--clr-white);
}

.container-heading {
  width: 35%;
  height: 70vh;
  position: relative;
}

.design-box1 {
  width: 40%;
  height: 70vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 2.5rem;
   /* background-color: orange; */
}

.curve-1 {
  height: 20vh;
  width: 60%;
  background-color: var(--clr-darkblue);
  opacity: 0.9;
  border-radius: 0.7rem;
  border-left: 0.4375rem solid var(--clr-white);
  border-top: 0.4375rem solid var(--clr-white);
    /* background-color: darkslateblue; */
}

.curve-2 {
  height: 20vh;
  width: 60%;
  background-color: var(--clr-darkblue);
  opacity: 0.9;
  border-radius: 0.7rem;
  border-left: 0.4375rem solid var(--clr-white);
  border-bottom: 0.4375rem solid var(--clr-white);
  /* background-color: red; */
}

.contact-info-box {
  width: 88%;
  height: 65vh;
  background-color: var(--clr-orange);
  position: absolute;
  opacity: 100;
  top: 3%;
  right: 0;
  z-index: 1;
  flex-direction: column;
  border-radius: 0.7rem;
  display: flex;
  /* background-color: chartreuse; */
}

.contact-detalis {
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 2.5rem;
   /* background-color:fuchsia; */
}

.contact-p {
  font-size: 1rem;
  padding: 1rem;
  margin-left: 34%;
  color: var(--clr-white);
}

.first-logo {
  height: 100px;
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5625rem;
  /* background-color: firebrick; */

}

.icon-div {
  height: 5rem;
  width: 5rem;
  background-color: var(--clr-darkblue);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.625rem;
}

.text-div2 {
  height: 70px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: aqua;  */
}
.font-weight{
  font-weight: bold;
  margin-top: 20px;
}

.text-div2 p {
  font-size: 1rem;
  color: var(--clr-white);
  margin-top: -0.4395rem;
}
.icon11 {
  font-size: 4rem;
  color: var(--clr-white);
}




/*------------ mobile responsive ----------- */
@media only screen and (max-width: 768px) {
  .container-1 {
    flex-direction: column;
    height: auto;
    gap: 1.625rem;
   
  }

  .conatiner-text {
    width: 100%;
    height: auto;
    /* background-color: green; */
  }

  .input-box {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
     /* background-color: black; */
  }

  .input-box input {
    height: 7vh;
    width: 50%;
    font-size: 1.4rem;
    color: var(--clr-black);
    border-radius: 0.4rem;
    border: none;
    outline: 0;
    padding: 0rem 1rem;

  }

  .input-1 input[type="text"]:focus {
    background-color: var(--clr-orange);
    color: white;

  }

  .input-1 {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: start;
    gap: 1.25rem;
    /* background-color: chartreuse; */

  }

  .text-area-conatiner {
    width: 100%;
    height: auto;
  }

  .text-area-conatiner textarea:focus {
    background-color: var(--clr-orange);
    color: var(--clr-white);
  }

  .text-area-conatiner textarea {
    width: 100%;
    height: 20vh;
    border-radius: 0.4rem;
    border: none;
    outline: none;
    font-size: 1.4rem;
    padding: 0.2rem 0;
  }

  .button-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;

  }

  .container-box p {
    font-size: 1rem;
  }

  .container-box h1 {
    font-size: 2rem;
  }

  .input-box input {
    height: 5vh;
    width: 49%;
    font-size: 1rem;
  }

  .text-area-conatiner textarea {
    width: 100%;
    height: 14vh;
    font-size: 1.4rem;
  }

  .contact-us-div1 {
    height: auto;
    padding: 1rem 0;
  }
  .contact-us-div1 {
    width: 100%;
    height: auto;
  }
  .contact-us-div2 {
    height: auto;
    justify-content: space-around;
    padding: 1rem 0;
  }
  .design-box1.design-box1 {
    margin-left: unset;
    width: 50%;
  }
  .container-heading {
    width: 98%;
    height: 70vh;
    position: relative;
    /* background-color: aqua; */
  }
  .curve-2,
  .curve-1 {
    width: 80%;
  }
  .contact-info-box {
    width: 96%;
  }
  .input-1 {
    justify-content: space-between;
  }
  .container-box {
    height: auto;
  }
  .contact-detalis {
    gap: 0;
  }
  .text-div2 p {
    font-size: 1rem;
  }
  .icon-div {
    height: 4.5rem;
    width: 4.5rem;
    margin-left: unset;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .contact-info-box {
    width: 92%;
    height: 65vh;
    position: absolute;
    opacity: 100;
    top: 3%;
    right: 0;
    z-index: 1;
    flex-direction: column;
    border-radius: 0.7rem;
    display: flex;
     /* background-color:khaki; */
  }
  .text-div2 p {
    font-size: 0.5rem;
    color: var(--clr-white);
  }
  .icon-div {
    height: 3.125rem;
    width: 3.125rem;
  }
  .icon11 {
    font-size: 2.5rem;
    color: var(--clr-white);
  }
  .design-box1 {
    margin-left: 0.625rem;
  }
}
@media (min-width: 1200px) {
  .contact-info-box {
    width: 88%;
    height: 66vh;
    position: absolute;
    /* background-color: blueviolet; */
    opacity: 100;
    top: 3%;
    right: 0;
    z-index: 1;
    flex-direction: column;
    border-radius: 0.7rem;
    display: flex;
  }
  .contact-detalis {
    width: 100%;
    height: 50vh;
    /* background-color:fuchsia; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 1.25rem;
  }
  .text-div2 p {
    font-size: 1rem;
    color: var(--clr-white);
  }
  .icon-div {
    height: 5rem;
    width: 5rem;
  }
  .input-box{
    gap: 1rem;
  }
}
@media only screen and (min-width: 1921px) {
  .contact-info-box {
    width: 88%;
    height: 65vh;
    /* background-color: red; */
    position: absolute;
    opacity: 100;
    top: 3%;
    right: 0;
    z-index: 1;
    flex-direction: column;
    border-radius: 0.7rem;
    display: flex;
  }
}
@media only screen and (max-width: 480px) {
  .contact-info-box {
    width: 95%;
    height: 55vh;
    /* background-color: black; */
    position: absolute;
    opacity: 100;
    top: 3%;
    right: 0;
    z-index: 1;
    flex-direction: column;
    border-radius: 0.7rem;
    display: flex;
  }
  
  .text-div2 p {
    font-size: 0.7rem;
    color: var(--clr-white);
  }
  .first-logo {
    height: 50px;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5625rem;
    /* background-color: firebrick; */
  }
  .icon-div {
    height: 2.5rem;
    width: 2.5rem;
    margin-left: unset;
  }
  .input-box input {
    height: 7vh;
    width: 50%;
    font-size: 0.9rem;
    /* background-color:white; */
    color: var(--clr-black);
    border-radius: 0.4rem;
    border: none;
    outline: 0;
    padding: 0rem 1rem;
  }
  
}
@media only screen and (max-width: 600px) {
  .input-box input {
    height: 7vh;
    width: 50%;
    font-size: 0.5rem;
    /* background-color:white; */
    color: var(--clr-black);
    border-radius: 0.4rem;
    border: none;
    outline: 0;
    padding: 0rem 1rem;
  }
  .design-box1 {
    width: 40%;
    height: 60vh;
    /* background-color: orange; */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-left: 2.5rem;
  }
  
  .text-area-conatiner textarea {
    width: 100%;
    height: 20vh;
    border-radius: 0.4rem;
    border: none;
    outline: none;
    font-size: 0.9rem;
    padding: 0.2rem 0;
  }
}