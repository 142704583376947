@import url(../../constants//colorConstans.css);

.HeroHomeMain {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
}
.HeroHome {
  width: 100%;
  height: 100vh;
  background-image: url("../../Asset/Home/homepagesliderimage.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-shadow: 0px 0px 5px var(--clr-Shadow);
  position: relative;
  overflow: hidden;
}
.HeroHome-position {
  width: 85%;
  /* height: 100vh !important; */
}
.HeroHome-Opecity {
  display: flex;
  height: 100%;
  background-color: var(--clr-opecity);
  position: relative;
}
.HeroHome-sideBar {
  position: relative;
  z-index: 5;
  text-align: center;
  width: 15%;
  background: linear-gradient(var(--clr-orange), var(--clr-orange), var(--clr-orange), var(--clr-blue) ,  var(--clr-blue)) ;
}
.HeroHome-sideBar-tetails ul {
  margin-top: 2.5rem;
  padding: 0;
  list-style: none;
}
.HeroHome-sideBar-tetails ul li a {
  text-decoration: none;
  color: var(--clr-white);
}
.HeroHome-sideBar-tetails ul li {
  width: 100%;
  position: relative;
  z-index: 5;
  text-align: start;
  color: var(--clr-white);
  padding: 0.6rem 0rem;
  border-top: 2px solid var(--clr-white);
  cursor: pointer;
  transition: 0.3s;
}
/* image */
.images_container {
  height: 30%;
}

.image-wripper img {
  height: 110px;
  width: 90px;
}
.HeroHome-sideBar-tetails ul li:hover {
  color: var(--clr-orange);
}
.HeroHome-sideBar-tetails ul li:nth-child(6) {
  position: relative;
  z-index: 5;
  border-bottom: 2px solid var(--clr-white);
}
.HeroHome-sideBar-tetails ul li span {
  position: relative;
  z-index: 5;
  margin-left: 12%;
}
.HeroHome-detail {
  width: 100%;
  height: 100vh;
  background-color: rgba(37, 37, 37, 0.54);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.HeroHome-detail_details {
  width: 80%;
  height: auto;
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
}
.HeroHome-detail_details h1 {
  font-size: 3.3125rem;
  font-family: "Poppins";
  color: #ffffff;
  font-weight: 500;
}
.We_container_we {
  width: 50%;
  height: auto;
  /* background-color: greenyellow; */
}
.hr_we {
  width: 130px;
  height: 6px;
  border-radius: 10px;
  background-image: linear-gradient(to right, #ee6c4d, #0c4c78);
  margin-top: -30px;
}
.We_container_we p {
  font-size: 2rem;
  font-family: "Arial";
  font-weight: 700;
  color: #ee6c4d;
}

.HeroHome-detail-what-we-do {
  width: 25%;
  background-color: var(--clr-orange);
}

.HeroHome-detail-paragraph p {
  line-height: 1.6rem;
  cursor: pointer;
}

.HeroHome-sideBar-logo-image {
  width: 100%;
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: space-evenly;
}
.HeroHome-sideBar-logo-image img {
  height: 1.6rem;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
}
.HeroHome-sideBar-logo-image img:hover {
  box-shadow: 0 0 0.3rem var(--clr-boxshadow-navbar);
}
.HeroHome-Sidebar-position {
  width: 100%;
  height: 80%;
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.HeroHome-Sidebar-position .log_in {
  margin-top: -2rem;
  width: 6rem;
  height: 2.8rem;
  border-radius: 0.3125rem;
  background: transparent;
  border: 2px solid var(--clr-orange);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Arial";
  font-weight: 700;
  font-size: 1.125rem;
  cursor: pointer;
  color: var(--clr-orange);
}
.HeroHome-Sidebar-position .log_in  a{
  text-decoration: none;
  color: var(--clr-orange);
  cursor: pointer;
  z-index: 5;
}
/* .HeroHome-Sidebar-position p {
  position: relative;
  z-index: 5;
  color: var(--clr-orange);
  margin: 10% 0%;
} */
/* .HeroHome-detail-what-we-do-line {
  width: 1rem;
  background-color: var(--clr-orange);
} */
.HeroHome-detail-btn button {
  margin-top: 1rem;
  padding: 0.8rem 0rem;
  width: 45%;
  border-radius: 0.6rem;
  color: var(--clr-black);
  border: none;
  font-size: 100%;
  cursor: pointer;
  transition: 0.4s;
}
.HeroHome-detail-btn button:hover {
  background-color: var(--clr-orange);
  color: var(--clr-white);
}

.HeroHome-detail-btn button:nth-child(2):hover {
  background-color: var(--clr-blue);
}
.HeroHome-sideBar-tetails img {
  padding: 10% 0%;
}
.HeroHome-sideBar-tetails {
  height: 80%;
  width: 100%;
  /* background-color: red; */
}
.HeroHome-sideBar-logo {
  height: 20%;
}
.HeroHome-position .slick-dots {
  top: 50% !important;
  bottom: 10px !important;
  right: -31%;
  transform: translateY(-50%) rotate(90deg);
}

.HeroHome-position .slick-dots li {
  margin: 0rem 0.4rem;
}
.HeroHome-position .slick-dots li button:before {
  color: var(--clr-white) !important;
  position: absolute;

  font-size: 20px;
  top: 0px;
  /* display: none; */
}
.herosidebarallgrids {
  position: absolute;
  height: 190px;
  width: 200px;
  z-index: 2;
  bottom: 0;
}
.sidebar-y-all-line {
  display: flex;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  position: relative;
}
.sidebar-y-line {
  background-color: var(--clr-orange);
  height: 100%;
  width: 1px;
  opacity: 0.3;
}
.sidebar-x-all-line {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}
.sidebar-x-line {
  width: 100%;
  height: 1px;
  opacity: 0.3;
  background-color: var(--clr-orange);
}
.icon {
  width: 2.0625rem;
  height: 2.0625rem;
  background-color: var(--clr-orange);
  color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* text-decoration: none; */
}

/*  */
.HeroHome1 {
  width: 100%;
  height: 100vh;
  background-image: url("../../Asset/Home/logistics_720.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-shadow: 0px 0px 5px var(--clr-Shadow);
  /* position: relative; */
  overflow: hidden;
}
.HeroHome2 {
  width: 100%;
  height: 100vh;
  background-image: url("../../Asset/Home/lorry_owner_720.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-shadow: 0px 0px 5px var(--clr-Shadow);
  position: relative;
  overflow: hidden;
}
.HeroHome3 {
  width: 100%;
  height: 100vh;
  background-image: url("../../Asset/Home/lorry_driver_720.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-shadow: 0px 0px 5px var(--clr-Shadow);
  position: relative;
  overflow: hidden;
}
.HeroHome4 {
  width: 100%;
  height: 100vh;
  background-image: url("../../Asset/Home/lorry_broker_720.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-shadow: 0px 0px 5px var(--clr-Shadow);
  position: relative;
  overflow: hidden;
}
.HeroHome5 {
  width: 100%;
  height: 100vh;
  background-image: url("../../Asset/Home/transporter_720.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-shadow: 0px 0px 5px var(--clr-Shadow);
  position: relative;
  overflow: hidden;
}

.What_we_box {
  width: 100%;
  height: auto;
  /* background-color: green; */
}
.What_we_box p {
  font-size: 2.375rem;
  font-weight: 700;
  font-family: "Arial";
  color: #ee6c4d;
}
.what_we_do_line {
  width: 30%;
  height: 8px;
  border-radius: 5px;
  background-image: linear-gradient(to right, #ee6c4d, #0c4c78);
  margin-top: -20px;
}
.company_name_conatiner {
  width: 90%;
  height: 50%;
  background-image: linear-gradient(to right, #ee6c4d, #0c4c78);
  border-radius: 3.125rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin: 1rem 0;
}
.company_name_conatiner h1 {
  /* text-align: center; */
  padding-left: 5rem;
  font-size: 76px;
  font-family: "Arial";
  font-weight: 400;
  color: #ffffff;
}
.p1{
  font-family: "Arial";
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.25rem;
  color: var(--clr-white);
}
.btn-box {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 1.25rem;
}
.btn-box-btn {
  width: 11.25rem;
  height: 60px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #ee6c4d;
  font-size: 1.375rem;
  font-family: "Arial";
  color: #ffffff;
  font-weight: 400;
  cursor: pointer;
  border: none;
}
.btn-box a {
  font-family: "Arial";
  font-size: 1.375rem;
  font-weight: 700;
  color: #ffffff;
}
.read-more {
  color: #ee6c4d;
  font-weight: 600;
  cursor: pointer;
}

/* show mobile view responsive code here */
.Mobile_show_container {
  width: 15%;
  height: 100%;
  background-image: linear-gradient(to right, #ee6c4d, #0c4c78);
  display: none;
  /* display: flex;
  justify-content:space-around ;
  align-items: center;
  flex-direction: column; */
}
.Mobile_show_container_inside {
  width: 100%;
  height: 100%;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.image-wripper_mobile {
  width: 50px;
  height: 50px;
  background-color: #0c4c7880;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.3125rem;
}
.image-wripper_mobile img {
  width: 40px;
  height: 40px;
}
.mobile_view_icons_container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
  gap: 40px;
}
.mobile_view_icons_container img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.HeroHome-sideBar-logo-image a{
  text-decoration: none;
  color: var(--clr-white);
}
.HeroHome-sideBar-logo-image_mobile_view {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* background-color: green; */
  justify-content: center;
  align-items: center;
  gap: 20px;
}

/* m-responsive-login */
.m-responsive-login{
  width: 100%;
  height: auto;
 
  display: none;

}
.m-responsive-login button{
  width: 5.25rem;
  height: 30px;
  background-color: transparent;
  border-radius: 5px;
  border: 2px  solid #ee6c4d;
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ee6c4d;
}
/*------------ mobile responsive ----------- */
@media only screen and (max-width: 768px) {
  .m-responsive-login{
    display: block;
  }
  .HeroHome-sideBar {
    display: none;
  }
  .Mobile_show_container {
    display: block;
  }
  .We_container_we p {
    font-size: 1rem;
  }
  .hr_we {
    margin-top: -10px;
  }
  .What_we_box p {
    font-size: 1rem;
  }
  .HeroHome-detail_details h1 {
    font-size: 2rem;
    padding-left: 2rem;
  }

  .HeroHome-position .slick-dots {
    top: 94% !important;
    bottom: 10px !important;
    right: 0;
    transform: translateY(-50%) rotate(0deg);
  }
  .HeroHome-position .slick-dots li button:before {
    font-size: 16px;
  }
  .HeroHome-detail_details {
    width: 95%;
    height: auto;
  
  }
  .company_name_conatiner {
    width: 100%;
    height: 20%;
    border-radius: 1.5rem;
  }
  .what_we_do_line {
    margin-top: -8px;
  }
  .HeroHome-detail_details .p1 {
    font-size: 1rem;
    line-height: 1.25rem;
  }
  .btn-box-btn {
    width: 107px;
    height: 48px;
    font-size: 1rem;
  }
  .btn-box a {
    font-size: 1rem;
  }
}

/* .... desktop responsive ...... */

@media (min-width: 992px) and (max-width: 1199px) {
  .HeroHome-position .slick-dots {
    right: -26%;
  }
}


