.RiyoLogisticsGetInTouch {
  margin: 4% 0%;
  position: relative;
  padding: 0% 4%;
  display: flex;
  background: linear-gradient(to right, var(--clr-orange), var(--clr-blue));
  color: var(--clr-white);
  align-items: center;
  z-index: 3;
  height: 65vh;
}
.RiyoLogisticsGetInTouch-heading {
  width: 60%;
  z-index: 3;
  position: relative;
}
.RiyoLogisticsGetInTouch-heading h1 {
  font-size: 5rem;
  font-weight: 400;
  z-index: 3;
  position: relative;
}
.RiyoLogisticsGetInTouch-details {
  width: 50%;
  z-index: 3;
  position: relative;
}

.RiyoLogisticsGetInTouch-details-flex-box {
  display: flex;
  gap: 1rem;
  z-index: 3;
  align-items: center;
  position: relative;
}
.RiyoLogisticsGetInTouch-background-logo {
  right: 5%;
  position: absolute;
  color: var(--clr-orange);
  z-index: 2;
}
.RiyoLogisticsGetInTouch-details-flex-box p {
  font-size: 18px;
}
.RiyoLogisticsGetInTouch-icon {
  height: 50px;
  width: 50px;
  background: linear-gradient(
    var(--blue-tranparentGetintouch),
    var(--orange-tranparent),
    var(--orange-tranparent)
  );
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.2s;
}

.RiyoLogisticsGetInTouch-icon:hover {
  box-shadow: 0 0 5px var(--clr-white);
}
@media only screen and (max-width: 760px) {
  .RiyoLogisticsGetInTouch {
    text-align: center;
    padding: 4% ;
    height: auto;
    flex-direction: column;
    position: relative;
  }
  .RiyoLogisticsGetInTouch-heading {
    width: 100%;
  }
  .RiyoLogisticsGetInTouch-heading h1{
    font-size: 2rem;
  }
  .RiyoLogisticsGetInTouch-details {
    width: 100%;
  }
  .RiyoLogisticsGetInTouch-details p{
    font-size: 10px;
  }
  .display-none{
    display: none;
  }
  .RiyoLogisticsGetInTouch-icon {
    height: 40px;
    width: 40px;
  }
  .RiyoLogisticsGetInTouch-background-logo img{
    height: 7rem;
    position: absolute;
    right: 2rem;
    top: 4rem;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .RiyoLogisticsGetInTouch-heading h1 {
    font-size: 4.5rem;
  }
  .RiyoLogisticsGetInTouch-icon {
    height: 30px;
    width: 30px;
  }
  .RiyoLogisticsGetInTouch-details-flex-box p {
    font-size: 16px;
  }
  .RiyoLogisticsGetInTouch-background-logo img {
    height: 150px;
  }
}

@media (max-width: 1280px) and (min-width: 1025px) {
  .RiyoLogisticsGetInTouch-heading h1 {
    font-size: 6rem;
  }
  .RiyoLogisticsGetInTouch-icon {
    height: 50px;
    width: 50px;
  }
  .RiyoLogisticsGetInTouch-details-flex-box p {
    font-size: 23px;
  }
  .RiyoLogisticsGetInTouch-background-logo img {
    height: 150px;
  }
}

@media (max-width: 1440px) and (min-width: 1281px) {
  .RiyoLogisticsGetInTouch-heading h1 {
    font-size: 6rem;
  }
  .RiyoLogisticsGetInTouch-icon {
    height: 50px;
    width: 50px;
  }
  .RiyoLogisticsGetInTouch-details-flex-box p {
    font-size: 20px;
  }
  .RiyoLogisticsGetInTouch-background-logo img {
    height: 200px;
  }
}

@media (max-width: 1600px) and (min-width: 1440px) {
  .RiyoLogisticsGetInTouch-heading h1 {
    font-size: 7rem;
  }
  .RiyoLogisticsGetInTouch-icon {
    height: 50px;
    width: 50px;
  }
  .RiyoLogisticsGetInTouch-details-flex-box p {
    font-size: 24px;
  }
  .RiyoLogisticsGetInTouch-background-logo img {
    height: 200px;
  }
}

@media (max-width: 1800px) and (min-width: 1601px) {
  .RiyoLogisticsGetInTouch-heading h1 {
    font-size: 8rem;
  }
  .RiyoLogisticsGetInTouch-icon {
    height: 50px;
    width: 50px;
  }
  .RiyoLogisticsGetInTouch-details-flex-box p {
    font-size: 30px;
  }
  .RiyoLogisticsGetInTouch-background-logo img {
    height: 250px;
  }
}

@media (max-width: 2000px) and (min-width: 1801px) {
  .RiyoLogisticsGetInTouch-heading h1 {
    font-size: 9rem;
  }
  .RiyoLogisticsGetInTouch-icon {
    height: 65px;
    width: 65px;
  }
  .RiyoLogisticsGetInTouch-details-flex-box p {
    font-size: 30px;
  }
  .RiyoLogisticsGetInTouch-background-logo img {
    height: 250px;
  }
}

@media (max-width: 2560px) and (min-width: 2001px) {
  .RiyoLogisticsGetInTouch-heading h1 {
    font-size: 11rem;
  }
  .RiyoLogisticsGetInTouch-icon {
    height: 70px;
    width: 70px;
  }
  .RiyoLogisticsGetInTouch-details-flex-box p {
    font-size: 40px;
  }
  .RiyoLogisticsGetInTouch-background-logo img {
    height: 350px;
  }
}
