@import url(../../constants/colorConstans.css);
.terms-conditions{
    height: 50vh;
    background-image: url(https://img.freepik.com/premium-photo/orange-landscape-featuring-highly-detailed-illustration-forest-mountains-background-romantic-riverscape-is-depicted-with-earth-tone-color-palette-reminiscent-style_899449-96319.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    display: flex;
    background-size: 100% 100%;
    align-items: center;
    justify-content: center;
}
.terms-box{
    overflow: hidden;
    position: relative;
    height: 60%;
    width: 70%;
    border-radius: 3.7rem;
    background-image: linear-gradient(var(--clr-darkblue), var(--clr-orange));
    display: flex;
    align-items: center;
}
.terms-box h1{
    font-size: 4.1rem;
    padding: 0% 4%;
    color: var( --clr-white);
    z-index: 1;
}
.terms-grid{
    height: 100%;
    position: absolute;
    width: 20%;
    left: 4%;
    top: 0;
    opacity: 0.4;
}
.truck {
    width: 50%;
    position: absolute;
    right: 0;
    bottom: 0;

}
.truck img{
    height: 5rem;
    opacity: 0.5;
}
.search-logo{
    position: absolute;
    right: 1%;
    height: 100%;
    opacity: 0.2;
}


@media only screen and (max-width: 600px) {
    .terms-box{
        overflow: hidden;
        position: relative;
        height:37%;
        width: 92%;
        border-radius: 2rem;
        background-image: linear-gradient(var(--clr-darkblue), var(--clr-orange));
        display: flex;
        align-items: center;
    }
    .terms-box h1{
        font-size: 1.3rem;
        padding: 0% 15%;
        color: var( --clr-white);
        z-index: 1;
    }
    .terms-grid{
        height: 110%;
        position: absolute;
        width: 35%;
        left: 4%;
        top: 0;
        opacity: 0.4;
    }
    .truck {
        width: 10%;
        display: flex;
        justify-content: end;
    }
    .truck img{
        height: 3rem;
        opacity: 0.5;
    }
}