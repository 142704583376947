@import url(../../constants/colorConstans.css);
.riyo-parivar-section{
    height: 86vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.riyoparivar-div{
    height: 75vh;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: blueviolet; */
}
.riyo-parivar-box1{
    height: 70vh;
    width: 49%;
    display: flex;
    position: relative;
    margin-top: 3%;
}
.riyo-parivar-imgbox{
    height: 100%;
    width: 60%;
    background-color: var(--clr-orange);
    border-radius: 0.5rem;
}
.riyo-parivar-imgbox1{
    height: 90%;
    width: 95%;
    background-image: url('../../Asset/RIYOservices/RIYOservicesParivaar.jpg');
    object-fit: cover;
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    right: 0;
    background-position: center;
    bottom: 5%;
    border-radius: 0.7rem;
}
.riyo-parivar-box2{
    height: 75vh;
    width: 50%;
    display: flex;
    justify-content: end;
    align-items: center;
      /* background-color: yellow; */
}
.riyo-parivar-imgbox2 img{
    width: 100%;
    height: 100%;
    border-radius: 1.0625rem;
    object-fit: cover;

}
.riyo-parivar-para{
    height: 90%;
    width: 90%;
    /* background-color: darkgoldenrod; */
}
.riyoparivar-circle{
    height: 5.25rem;
    width: 5.25rem;
    border-radius: 100%;
    background-image: linear-gradient(var(--clr-darkblue), var(--clr-orange));
    animation-name: animate;
}
@keyframes animate{
    0% {
        transform: scale(0.6) rotate(0deg);
    }
    50% {
        transform: scale(1) rotate(460deg);
    }
    100% {
        transform: scale(0.6) rotate(360deg);
        
    }
}

.riyoparivar-circle{
    animation: animate 3s infinite;
}
.parivarcircle-text{
  height: 21%;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
   /* background-color: khaki; */
}
.riyo-parivar-text{
    color:var(--clr-darkblue);
    font-family: Arial;
    font-size: 3.7rem;
}
.riyo-parivar-para2{
    height: 84%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
     /* background-color: brown; */
    
}
.riyo-parivar-para2 p{
    font-size: 1.2rem;
    color: var( --clr-black);
    font-family: Arial;
    line-height: 2rem;

}
.riyo-parivar-para2 button{
    height: 4.6rem;
    width: 15rem;
    cursor: pointer;
    background-color:var(--clr-darkblue);
    color: var( --clr-white);
    font-size: 1.4rem;
    border: none;
    border-radius: 0.3125rem;
    box-shadow: 0rem .3125rem .9375rem -0.1875rem var(--clr-darkblue);
    
}



@media (min-width: 62rem) and (max-width: 74.9375rem) {
    .riyo-parivar-para{
        height: 90%;
        width: 90%;
        
    }
    .riyo-parivar-para2 p{
        font-size: 1rem;
        color: var( --clr-black);
        font-family: Arial;
    
    }
    
    .riyo-parivar-para2 button{
        height: 4.6rem;
         width: 15rem;
        background-color: var(--clr-darkblue);
        color: var( --clr-white);
        font-size: 1.7rem;
        border: none;
        border-radius: .3125rem;
        
    }
    
    .riyo-parivar-text{
        font-size: 3.2rem;
        color:var( --clr-black);
        position: absolute;
        font-family: Arial;
    }
    .riyoparivar-circle{
        height: 70%;
        width: 20%;
        border-radius: 100%;
        background-color: var(--clr-darkblue);
    }
    
  }
  @media (min-width: 75rem) {
  }
  @media only screen and (min-width: 120.0625rem) {
   
  }
  @media only screen and (max-width:600px) {
    .riyo-parivar-section{
        height: auto;
        width: 100%;
        flex-direction: column;
        padding: 5% 0%;
    }
    .riyoparivar-div{
        height: 100%;
        width: 100%;
        flex-direction: column;
    }
    .riyo-parivar-text{
        font-size: 2.3rem;
        margin: 0;
    }
    .riyo-parivar-box1{
        height: 18rem;
        width: 90%;
        background-size: 100% 100%;
    }
    .riyo-parivar-box2{
        height: 100%;
        width: 100%;
        flex-direction: column;
    }
    .riyo-parivar-para{
        height: auto;
        width: 90%;
    }
   
    .riyo-parivar-para2 p{
        font-size: 1rem;
        line-height: 2rem;
    }
    .riyo-parivar-para2 button{
        height: 8vh;
        width: 50%;
        font-size: 1.4rem;
    }
  }
