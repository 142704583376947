@import url(../../constants/colorConstans.css);
.AboutBusiness-div1{
    width: 100%;
    height: 47vh;
    background-image: url(../../image/Group\ 3670.png);
    background-size: 100% 100%;
    object-fit: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.AboutBusiness-heading{
    height: 10vh;
    width: 50%;
    /* background-color: red; */
    display: flex;  
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.AboutBusiness-heading h1{
    color:var( --clr-white);
    font-size: 4.2rem;  
    font-family: Arial;
}
.AboutBusiness-heading p{
    color: var( --clr-white);
    font-size: 1.2rem;  
    font-family: Arial;
}

.about-para{
    color:var(--clr-orange);
    font-size: 1.6rem;
    font-family: Arial;
}
.AboutBusiness-div2{
    width: 100%;
    height: auto;
    /* background-color: aqua; */
    display: flex;
    padding: 5rem 0rem;
    background-color:#E6EFFB;
    
}
.box1-1{
    height: auto;
    width: 50%;
    /* background-color: yellow; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left:3.5rem;
    
}
.box-2{
    height: auto;
    width: 50%;
    /* background-color:red; */
}

.box1-1 h5{
    font-size: 1.6rem;
    margin-top: 1rem;
    font-family: Arial;
    color: var(--clr-darkblue); 
    
}
.box1-11{
    height: auto;
    width: 90%;
    /* background-color: white; */
    
}
.riyobusiness-line{
    height: 1%;
    width:10%;
    border-radius: 2rem;
    margin-top: 1.1rem;
    background-image: linear-gradient(var(--clr-orange), var(--clr-darkblue));
}
.para1-1{
    font-size: 1.2rem;
    margin-top: 2.1rem;
    line-height: 2rem;
    font-family: Arial;
    color: var( --clr-black);
}
.btn-1-1{
    height: 6.6vh;
    width: 39%;
    border-radius: .4375rem;
    margin-top: 3rem;
    font-family: Arial;
    border: none;
    cursor: pointer;
    color: var(--clr-black);
    background-color:var( --clr-white);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
    
}
.btn-1-1:hover{
    background-color: var(--clr-orange);
    color: var(--clr-white);
    transition: 1s;

}
.btn-1-1:hover .circle{
    transform: rotate(50deg);
    transition: 1s;
}
.circle{
    height: 70%;
    width: 12%;
    border-radius: 100%;
    background-color: var(--clr-darkblue);
    margin-left: 1.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--clr-white);
}
.circle p{
    font-size: 30px;
    font-family: Arial;
}
.box2-2{
    height: auto;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color:red; */
    
}
.box2-22{
    height: 60vh;
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    gap: 2.1875rem;
    justify-content: end;
    /* background-color: white; */
}

 .img-box11{
    height: 60vh;
    width: 35%;
    border-radius: 0.5rem;
    flex-direction: column;
    display: flex;
    gap: 1.5625rem;
    justify-content: end;
    /* background-color: black; */
    
 }
 .image-box11{
    height: 25vh;
    width: 100%;
    background-image: url("../../image/about1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 0.5rem;
 }
 .image-box22{
    height: 25vh;
    width: 100%;
    /* background-image: url("../../image/about2.png"); */
    background-repeat: no-repeat;
    background-image: url("../../image/about3.png");
    background-size: 100% 100%;
    border-radius: 0.5rem;
     /* background-color:chartreuse; */

 }
 .img-box-22{
    height: 60vh;
    width: 35%;
    border-radius: 0.5rem;
    flex-direction: column;
    justify-content: start;
    display: flex;
    gap: 1.5625rem;
     /* background-color: black; */
    
 }
 .image-box33{
    height: 25vh;
    width: 100%;
    /* background-image: url("../../image/about3.png"); */
    background-repeat: no-repeat;
    background-image: url("../../image/about2.png");
    background-size: 100% 100%;
    border-radius: 0.5rem;
    /* background-color:chartreuse; */
 }
 .image-box44{
    height: 30vh;
    width: 100%;
    background-image: url("../../image/about4.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 0.5rem;
    /* background-color:chartreuse; */

 }

 @media only screen and (max-width:600px) {
    .AboutBusiness-div1{
        height: 30vh;
        width: 100%;
    }
    .AboutBusiness-heading h1{
        font-size: 2rem;
    }
    .AboutBusiness-heading p{
        font-size: 1rem;
    }
    .AboutBusiness-div2{
        height: auto;
        width: 100%;
        flex-wrap: wrap;
    }
    .btn-1-1{
        width: 50%;
        font-size: 0.9rem;
    }
    .circle {
        height: 50%;
    }
    .box1-1{
        padding: 0% 0%;
        width: 100%;
    }
    .box2-2{
        width: 100%;
    }
    .box2-22{
        height: 60vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0% 4%;
    }
    
     .img-box11{
        height: 50vh;
        width: 45%;
        /* background-color: black; */
        border-radius: 0.5rem;
        flex-direction: column;
        
     }
     .image-box11{
        height: 25vh;
        width: 100%;
     }
     .image-box22{
        height: 25vh;
        width: 100%;    
     }
     .img-box-22{
        height: 50vh;
        width: 45%;
        /* background-color: black; */
        border-radius: 0.5rem;
        flex-direction: column;
        justify-content: start;
        display: flex;
        gap: 25px;
        
     }
     .image-box33{
        height: 25vh;
        width: 100%;
     }
     .image-box44{
        height: 30vh;
        width: 100%;
     }
 }


 /* .... desktop responsive ...... */

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199px) {
    .btn-1-1{
        height: 6.9vh;
        width: 69%;
        border-radius: .4375rem;
        margin-top: 3rem;
        font-family: Arial;
        border: none;
        cursor: pointer;
        color: var(--clr-black);
        background-color:var( --clr-white);
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1); 
    }
}


 
