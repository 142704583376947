.RiyoPackage {
    padding: 2% 4%;
    display: flex;
    align-items: center;
    background: linear-gradient(to right, var(--clr-orange), var(--clr-blue));
    align-items: center;
  }
  .comming{
    color: white;
  }
  .RiyoPackage-heading {
    display: flex;
    width: 40%;
    flex-direction: column;
    color: var(--clr-white);
    font-weight: 700;
    line-height: 1rem;
  }
  .RiyoPackage-all-details {
    position: relative;
    display: flex;
    justify-content: end;
    width: 60%;
    height: 100%;
    gap: 2rem;
  }
  .RiyoPackage-details-card-details p {
    font-weight: 400;
    color: var(--clr-text);
    margin: 0;
  }
  .RiyoPackage-heading h1 {
    text-align: start;
    font-size: 6rem;
    font-weight: 500;
    color: var(--clr-blue);
  }
  .RiyoPackage-heading h2 {
    font-size: 3rem;
    font-weight: 400;
  }
  .RiyoPackage-all-details-card,
  .RiyoPackage-all-details-card1 {
    width: 46%;
    background-color: var(--clr-white);
    position: relative;
    border-radius: 1rem;
  }
  .RiyoPackage-details-card-heading,
  .Riyocardbottomline,
  .Riyocardbottomline1 {
    font-weight: bold;
    text-align: center;
    color: var(--clr-white);
    padding: 0 0;
    background-color: var(--clr-orange);
  }
  .RiyoPackage-details-card-heading {
    border-radius: 0.3rem 0.3rem 0 0;
    padding: 0.1% 0%;
  }
  .RiyoPackage-details-card-heading h3,
  .Riyocardbottomline h3 {
    font-weight: 600;
  }
  .RiyoPackage-details-card-details {
    padding: 3% 4%;
    display: flex;
    height: auto;
    align-items: center;
    gap: 1rem;
    color: var(--clr-text);
  }
  .RiyoPackage-details-card-details img {
    height: 20px;
  }
  .Riyocardbottomline {
    border-radius: 0 0 0.3rem 0.3rem;
  }
  .Riyocardbottomline1 {
    border-radius: 0 0 0.3rem 0.3rem;
    width: 100%;
    position: relative;
    padding: 0.1% 0%;
    background-color: var(--clr-blue);
    bottom: 0;
  }
  .Riyocardbottomline2 {
    border-radius: 0 0 0.3rem 0.3rem;
    width: 100%;
    position: absolute;
    padding: 0.1% 0%;
    color: var(--clr-white);
    background-color: var(--clr-blue);
    bottom: 0;
    text-align: center;
  }
  @media only screen and (max-width: 760px) {
      .RiyoPackage {
        flex-direction: column;
        padding: 4%;
        height: auto;
      }
    .Riyocardbottomline2 {
      position: relative;
    }
    .RiyoPackage-all-details {
      display: flex;
      flex-direction: column;
      justify-content: end;
      width: 100%;
      height: 100%;
    }
    .RiyoPackage-heading {
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .RiyoPackage-heading h1 {
      font-size: 3rem;
      text-align: start;
      margin-top: 10px;
    }
    .RiyoPackage-heading h2 {
      font-size: 1rem;
    }
    .RiyoPackage-all-details-card,
    .RiyoPackage-all-details-card1 {
      width: 100%;
      background-color: var(--clr-white);
      position: relative;
      border-radius: 1rem;
    }
  }
  @media (max-width: 1024px) and (min-width: 768px) {
    .RiyoPackage-heading h1 {
      font-size: 4rem;
    }
    .RiyoPackage-heading h2 {
      font-size: 2rem;
    }
    .RiyoPackage-details-card-details p {
      font-size: 14px;
    }
  }
  @media (max-width: 1280px) and (min-width: 1025px) {
    .RiyoPackage-heading h1 {
      font-size: 6rem;
    }
    .RiyoPackage-heading h2 {
      font-size: 3rem;
    }
    .RiyoPackage-details-card-details p {
      font-size: 17px;
    }
  }
  .RiyoPackage-details-card-details img{
      height: 20px;
  }
  .Riyocardbottomline{
      border-radius: 0 0 0.3rem 0.3rem  ;
  }
  @media (max-width: 1600px) and (min-width: 1440px) {
    .RiyoPackage-heading h1 {
      font-size: 6rem;
    }
    .RiyoPackage-heading h2 {
      font-size: 3rem;
    }
    .RiyoPackage-details-card-details p {
      font-size: 19px;
    }
  }
  @media (max-width: 1800px) and (min-width: 1601px) {
    .RiyoPackage-heading h1 {
      font-size: 7.5rem;
    }
    .RiyoPackage-heading h2 {
      font-size: 3.6rem;
    }
    .RiyoPackage-details-card-details p {
      font-size: 20px;
    }
  }
  @media (max-width: 2000px) and (min-width: 1801px) {
    .RiyoPackage-heading h1 {
      font-size: 8rem;
    }
    .RiyoPackage-heading h2 {
      font-size: 4rem;
    }
    .RiyoPackage-details-card-details p {
      font-size: 23px;
    }
  }
  @media (max-width: 2560px) and (min-width: 2001px) {
    .RiyoPackage-heading h1 {
      font-size: 10rem;
    }
    .RiyoPackage-heading h2 {
      font-size: 5rem;
    }
    .RiyoPackage-details-card-details p {
      font-size: 29px;
    }
  }