.RiyoLogisticsTestimonials {
  overflow: hidden;
  position: relative;
  padding: 2% 0%;
}
.RiyoLogisticsTestimonials-heading {
  width: 100%;
  padding: 0 4%;
}
.slick-slide {
  overflow: visible;
}
.RiyoLogisticsTestimonials-heading h1 {
  font-size: 4.7rem;
  color: var(--clr-blue);
  font-weight: 450;
}
.RiyoLogisticsTestimonials-slider-Main {
  height: auto;
  position: relative;
}
.RiyoLogisticsTestimonials-heading-line {
  margin: 1% 0%;
  width: 10rem;
  height: 0.3rem;
  background: linear-gradient(to right, var(--clr-orange), var(--clr-blue));
  border-radius: 0px 100px 100px 0px;
}
.RiyoLogisticsTestimonials-slider-box {
  position: relative;
  padding: 0% 4%;
  align-items: center;
  display: flex;
  background-color: var(--blue-tranparent);
  height: 49vh;
}
.RiyoLogisticsTestimonials-slider-Main .slick-slide {
  padding-top: 0.52%;
  width: 100%;
}
.RiyoLogisticsTestimonials-slider-box-paragraph {
  width: 65%;
}
.RiyoLogisticsTestimonials-slider-box-paragraph p {
  font-size: 19px;
  padding: 3% 0%;
  line-height: 1.8rem;  
  padding-right: 7%;
  color: var(--text-clr);
}
.RiyoLogisticsTestimonials-slider-box-paragraph p:nth-child(2) {
  line-height: 1.3rem;
}
.RiyoLogisticsTestimonials-slider-box-Image {
  padding: 1%;
  position: absolute;
  height: 100%;
  width: 33%;
  right: 4%;
  bottom: 1%;
  object-fit: cover;
}
.RiyoLogisticsTestimonials-slider-box-Image img {
  height: 100%;
  z-index: 5;
  position: relative;
  border-radius: 0.5rem;
}
.riyo-slider-image-background-box {
  top: 0;
  left: 0;
  position: absolute;
  height: 80%;
  width: 70%;
  background: var(--clr-orange);
}
.riyo-slider-image-background-box2 {
  position: absolute;
  height: 80%;
  width: 70%;
  right: 0;
  bottom: 0;
  background: var(--clr-orange);
}
.RiyoLogisticsTestimonials-slider-Main .slick-dots {
  bottom: 10px !important;
  left: -150px;
}
.RiyoLogisticsTestimonials-slider-Main .slick-dots li {
  margin: 0rem 0.4rem;
}
.RiyoLogisticsTestimonials-slider-Main .slick-dots li button:before {
  color: var(--clr-orange) !important;
  font-size: 10px !important;
}

@media only screen and (max-width: 760px) {
  .RiyoLogisticsTestimonials-heading h1 {
    font-size: 1.4rem;
  }
  .RiyoLogisticsTestimonials-heading-line {
    width: 3.7rem;
    height: 2px;
  }
  .RiyoLogisticsTestimonials-slider-box-paragraph {
    width: 100%;
  }
  .RiyoLogisticsTestimonials-slider-Main {
    height: auto;
    position: relative;
  }
  .RiyoLogisticsTestimonials-slider-box {
    flex-direction: column;
    height: auto;
    align-items: center;
    justify-content: center;
  }
  .RiyoLogisticsTestimonials-slider-box-Image {
    padding: 3%;
    height: 40vh;
    position: relative;
    width: 93%;
    margin-left: 8%;
  }
  .RiyoLogisticsTestimonials-slider-box {
    padding: 0 4% 12% 4%;
    height: auto;
  }
  .RiyoLogisticsTestimonials-slider-box-paragraph p {
    padding: 0% 0%;
    padding-right: 0%;
  }
  .RiyoLogisticsTestimonials-slider-Main .slick-dots {
    left: 0px;
    top: 94%;
  }
}
@media (max-width: 1280px) and (min-width: 1025px) {
  .RiyoLogisticsTestimonials-heading h1 {
    font-size: 4rem;
  }
 
  .RiyoLogisticsTestimonials-slider-box-paragraph p {
    font-size: 1.2rem;
  }
  .RiyoLogisticsTestimonials-slider-box-paragraph p:nth-child(2) {
    line-height: 2rem;
  }
}

@media (max-width: 1440px) and (min-width: 1281px) {
  .RiyoLogisticsTestimonials-heading h1 {
    font-size: 4rem;
  }
 
  .RiyoLogisticsTestimonials-slider-Main .slick-dots {
    top: 87%;
  }
  .RiyoLogisticsTestimonials-slider-box-paragraph p {
    font-size: 19px;
  }
}

@media (max-width: 1600px) and (min-width: 1441px) {
  .RiyoLogisticsTestimonials-heading h1 {
    font-size: 4rem;
  }
  .RiyoLogisticsTestimonials-slider-box-paragraph p {
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
  .RiyoLogisticsTestimonials-slider-box-paragraph p:nth-child(2) {
    line-height: 2rem;
  }
 
}

@media (max-width: 1800px) and (min-width: 1601px) {
  .RiyoLogisticsTestimonials-heading h1 {
    font-size: 4rem;
  }
  .RiyoLogisticsTestimonials-slider-box-paragraph p {
    font-size: 29px;
    line-height: 2.5rem;
  }
  .RiyoLogisticsTestimonials-slider-box-paragraph p:nth-child(2) {
    line-height: 2.3rem;
  }

}

@media (max-width: 2000px) and (min-width: 1801px) {
  .RiyoLogisticsTestimonials-heading h1 {
    font-size: 4rem;
  }
 
  .RiyoLogisticsTestimonials-slider-box-paragraph p {
    font-size: 29px;
    line-height: 3rem;
  }
  .RiyoLogisticsTestimonials-slider-box-paragraph p:nth-child(2) {
    line-height: 2.7rem;
  }
  .RiyoLogisticsTestimonials-slider-Main .slick-dots {
    top: 87%;
  }
}

@media (max-width: 2560px) and (min-width: 2001px) {
  .RiyoLogisticsTestimonials-heading h1 {
    font-size: 4rem;
  }
  .RiyoLogisticsTestimonials-slider-box-paragraph p {
    font-size: 40px;
    line-height: 3.4rem;
  }
  .RiyoLogisticsTestimonials-slider-box-paragraph p:nth-child(2) {
    line-height: 3rem;
  }
 
}
