@import url(../../constants/colorConstans.css);

.download-div {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.register-div{
  margin-top: 5%;
}
.register-div h4{
  color: var(--clr-darkblue);
  font-size: 4.1rem;
  font-family: Arial;
  font-weight:300;
  margin: 0;
}
.download-div2 {
  height: 80%;
  width: 90%;
  display: flex;
  gap: 2.5rem;
}
.download-box1 {
  height: 26%;
  width: 80%;
  padding: 0.3125rem;
  border: 0.0625rem solid rgb(241, 238, 238);
  box-shadow: 0px 11px 15px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  /* background-color: red;  */
}
.download-box2 {
  height: 26%;
  width: 80%;
  padding: 0.3125rem;
  border: 0.0625rem solid rgb(241, 238, 238);
  box-shadow: 0px 11px 15px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
    /* background-color: yellowgreen; */
}
.download-box3 {
  height: 26%;
  width: 80%;
  border: 0.0625rem solid rgb(241, 238, 238);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 0px 11px 15px 0px rgba(0, 0, 0, 0.1);
  padding: 0.3125rem;
  border-radius: 5px;
    /* background-color:blueviolet; */
}
.div-for-logo {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1.25rem;
  /* background-color: red; */
}
.div-for-sub {
  height: 25%;
  width: 90%;
  border-radius: 0.625rem;
  background-color: #ff0b0b;
  display: flex;
  align-items: center;
  justify-content: center;
}
.div-for-sub p {
  color: var(--clr-white);
  font-size: 1.6rem;
  font-family: Arial;
}
.like {
  height: 3.125rem;
  width: 3.125rem;
  background-color: var(--clr-darkblue);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.like-dislike {
  font-size: 1.6rem;
  color: var(--clr-white);
}
.download-box1 img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.download-box2 img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.subscribe p {
  color: var(--clr-white);
  font-size: 1.6rem;
  font-family: Arial;
}
.download-wrap {
  height: 100%;
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.download-img-wrap {
  height: 100%;
  background-image: linear-gradient(var(--clr-darkblue), var(--clr-lightblue));
  display: flex;
  justify-content: end;
  align-items: end;
  border-radius: 2.5rem;
}
.download-img-border {
  height: 1.875rem;
  width: 1.875rem;
  background-color: var(--clr-orange);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.check-icon {
  color: var(--clr-white);
  font-size: 2rem;
}
.subscribe-logo {
  height: 37%;
  width: 42%;
  border-radius: 100%;
  background-color: var(--clr-darkblue);
}
.download-text {
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.download-content {
  height: 30%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.download-circle {
  height: 4.125rem;
  width: 4.125rem;
  background-color: var(--clr-orange);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle3 {
  height: 4.125rem;
  width: 4.125rem;
  background-color: var(--clr-orange);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.download-circle h1 {
  color: var(--clr-white);
  font-size: 2.5rem;
  font-weight: 500;
}
.download-content h2 {
  color: var(--clr-darkblue);
  font-size: 1.5rem;
}
.download-content p {
  font-size: 1.2rem;
  font-family: Arial;
  color: var(--clr-black);
}
.download-double-box {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* background-color: yellow; */
}


@media only screen and (max-width:600px) {
  .download-div {
    height: auto;
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .register-div{
    width: 100%;
  }
  .register-div h4{
    text-align: start;
    font-size: 2.3rem;
  }
  .download-div2 {
    height: 100%;
    width: 90%;
    display: flex;
    margin-top: 3rem;
  }
  .download-box1 {
    /* height: 100%; */
    width: 100%;
    flex-direction: column;
  }
  .download-box2 {
    /* height: 100%; */
    width: 100%;
    flex-direction: column;
  }
  .download-box3 {
    height: 15vh;
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .div-for-logo {
    gap: 9%;
    height: 40%;
    width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
  }
  .div-for-sub {
    height: 3vh;
    width: 100%;
    flex-direction: column;
  }
  .div-for-sub p {
    font-size: 1.5rem;
  }
  .like {
    height: 2.125rem;
    width: 2.125rem;
    flex-direction: column;
  }
  .like-dislike {
    font-size: 1.4rem;
  }
  .download-box1 img {
    object-fit: cover;
    height: 80%;
    width: 100%;
  }
  .download-box2 img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  .div-for-sub p {
    font-size: 1rem;
  }
  .download-wrap {
    width: 30%;
    flex-direction: column;
  }
  .download-img-wrap {
    height: auto;
    /* width: 20%; */
    flex-direction: column;
  }
  .download-img-border {
    height: 1.2rem;
    width: 1.2rem;
    flex-direction: column;
  }
  .check-icon {
    font-size: 2rem;
  }
  .subscribe-logo {
    height: 37%;
    width: 42%;
  }
  .download-text {
    height: 70%;
    width: 100%;
    flex-direction: column;
  }
  .download-content {
    height: 32%;
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: space-evenly;
  }
  .download-circle {
    height: 2rem;
    width: 2rem;
   flex-direction: column;
   display: none;
  }
  .circle3 {
    height: 2rem;
    width: 2rem;
    flex-direction: column;
    /* margin-top: 4rem; */
  }
  .download-circle h1 {
    font-size: 1rem;
  }
  .download-content h2 {
    font-size: 1.2rem;
    margin-top: 0rem;
  }
  .download-content p {
    font-size: 1rem;
    margin-top: 0rem;
  }
  .download-double-box {
    width: 100%;
    height: 78%;
  }
}
