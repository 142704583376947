:root{
    --clr-darkblue:#0c4c78;
    --clr-orange: #ee6c4d;
    --clr-white:#FCFCFC;
    --clr-black:#505050;
    --clr-lightblue:#95cff6;
    --clr-bluefade:rgb(211, 208, 249);;
    --clr-orangefade:rgb(249, 211, 211);
    --clr-blue: #0c4c78;
    --clr-orange: #ee6c4d;
    --clr-white: #FCFCFC;
    --text-clr: #505050;
    --blue-tranparent:rgba(230, 239, 251, 1);
    --blue-tranparentGetintouch:rgb(12, 44, 120);
    --blue-tranparent-25:rgba(230, 239, 251, 1);
    --orange-tranparent:rgba(238, 108, 77, 0.25);
    --orange-second-tranparent:rgba(238, 108, 77, 0.50);
    --white-transparent: rgba(252, 252, 252, 0.25);
    --clr-qr:rgba(80, 80, 80, 0.199);
}

